<div class="modal-body">
  <div class="row">
    <div class="col-12 text-center">
      <h3>{{ 'DEBT.PROPOSAL.ACCEPTED-PROPOSAL' | translate }}</h3>
      <p>{{ 'DEBT.PROPOSAL.ACCEPTED-PROPOSAL-TEXT' | translate }}</p>
    </div>
  </div>
  <div class="row">
    <div class="col-12 text-center">
      <button class="button primary" (click)="close()">
        {{ 'BUTTONS.OK' | translate }}
      </button>
    </div>
  </div>
</div>
