<app-loading [show]="loading"></app-loading>
<div class="auth-content">
  <div class="row inside-content">
    <div
      class="top-container col-12 d-flex flex-column justify-content-between"
    >
      <div class="header d-flex align-items-center justify-content-between">
        <a href="/">
          <img
            src="./assets/images/Logo_1.svg"
            alt="logo Ipanema"
            rel="preload"
          />
        </a>
        <div class="row justify-content-end">
          <div class="col-auto">
            <a routerLink="/"></a>
            <button
              class="button light btn-rollback"
              type="button"
              (click)="rollback()"
              *ngIf="btnRollback"
            >
              <em class="fa fa-arrow-left fa-1x"></em>
              {{ 'BUTTONS.RETURN' | translate }}
            </button>
          </div>
        </div>
      </div>

      <div class="row justify-content-center">
        <div
          class="title-col col-10 col-sm-8 col-lg-5 col-xl-3 align-items-center"
        >
          <h1 class="title" *ngIf="title">{{ title }}</h1>
          <h2 class="subtitle" *ngIf="subtitle">{{ subtitle }}</h2>
        </div>
      </div>
    </div>

    <div class="content-container col-12">
      <div class="row">
        <div
          class="
            col-form col-12
            d-flex
            align-items-center align-self-lg-stretch
            justify-content-center
          "
        >
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>
