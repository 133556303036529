<div class="modal-body">
  <div class="header">
    <h1>
      {{ 'UNCONFIRMED-ACCOUNT.TITLE' | translate }}
    </h1>
    <h3 class="mt-2">
      {{ 'UNCONFIRMED-ACCOUNT.SUBTITLE' | translate }}
      <strong>
        {{ phoneNumber }}
      </strong>
      {{ 'UNCONFIRMED-ACCOUNT.SUBTITLE_COMPLEMENT' | translate }}
    </h3>
  </div>
  <form [formGroup]="confirmAccountForm" class="mt-3">
    <div class="col-12">
      <app-box-input id="code" type="sms-code" label="{{ 'RECOVERY-PASSWORD.SMS-VALIDATE-LABEL' | translate }}"
        placeholder="{{
          'RECOVERY-PASSWORD.SMS-VALIDATE-PLACEHOLDER' | translate
        }}" controlName="code" (valueChanged)="getInputValueCode($event)" [visible]="true"></app-box-input>
    </div>
  </form>
  <div class="row mt-3">
    <div class="col-12 text-center">
      <button class="button mr-3" id="btn_cancel_userAccount" (click)="close()">
        {{ 'BUTTONS.CANCEL' | translate }}
      </button>
      <button class="button primary" id="btn_confirm_send_userAccount" (click)="confirm()"
        [disabled]="!confirmAccountForm.valid">
        {{ 'DEBT.PROPOSAL.BUTTON-SEND-CONFIRM' | translate }}
      </button>
    </div>
  </div>
</div>