<ng-container #content>
  <div class="modal-body">
    <div id="container-terms">
      <div class="row">
        <div class="col-md-4 col-lg-4 col-xs-12 col-sm-12">
          <a routerLink="/" (click)="close($event)" tabindex="0">
            <img class="logo-ipanema" src="../assets/images/logo.svg" alt="Ipanema" rel="preload" />
          </a>
        </div>
        <div class="col-md-8 col-lg-8 col-xs-12 col-sm-12">
          <div class="close-button">
            <a class="close-text" (click)="close($event)"><span class="close-icon">{{
                'TERMS.CLOSE-BUTTON' | translate
                }}</span>
              {{ 'BUTTONS.CLOSE' | translate }}</a>
          </div>
        </div>
      </div>
      <div class="container justify-content-md-center wrap-title">
        <div class="col-sm-10">
          <h1>{{ 'TERMS.COOKIE' | translate }}</h1>
        </div>
      </div>
      <div class="container justify-content-md-center terms-body">
        <div class="col-sm-12 text-justify">

          <h6><strong>Introdução</strong></h6>
          <p>A presente Política de Cookies complementa os Termos e Condições de Uso e Política de Privacidade do FIDC
            Ipanema (Fundo de Investimento em Direitos Creditórios Multissegmentos NPL Ipanema VI – Não Padronizado),
            disponíveis em nosso Portal para os usuários de nossos serviços.</p>
          <p>Nesse sentido, o nosso objetivo é garantir que todos os usuários se familiarizem com todas as nossas
            práticas de proteção e privacidade de dados e, desta forma, tenham todas as informações necessárias para
            navegar corretamente.</p>

          <h6>
            <strong>E afinal o que são esses cookies?</strong>
          </h6>
          <p>
            Esses cookies que estamos falando não são aqueles biscoitos americanos deliciosos, mas são muito bons
            também. Os cookies são arquivos de texto criados pelos websites que podem armazenar dados relativos aos
            hábitos de navegação, preferências de busca e outras informações, ajudando a personalizar seu acesso.
          </p>

          <h6><strong>Como isso funciona na prática?</strong> </h6>
          <p>
            Imagine um restaurante que você vai com frequência. Ao entrar pela porta, você é levado à sua mesa
            preferida, o seu suco e o seu prato predileto são servidos e, para finalizar, aquele cafézinho forte. Ao
            sair, seu carro já está lá na porta te esperando. Tudo isso sem você ter que fazer nada.
          </p>
          <p>
            Os cookies funcionam mais ou menos assim, prevendo suas preferências e escolhas. Ajudando que o website
            lembre das suas ações ao longo do tempo e tornando a sua experiência de navegação a mais agradável possível.
          </p>


          <h6><strong>Por que usamos cookies? </strong></h6>

          <p>Usamos os cookies para facilitar e melhorar a navegação, reconhecendo as preferências dos usuários e
            adaptando o website aos seus interesses e necessidades.</p>
          <p>Com os cookies, entendemos como é a interação do usuário com os nossos conteúdos e, desta forma, aprendemos
            com o seu comportamento, oferecemos um conteúdo personalizado e melhoramos a sua experiência.</p>
          <p><strong>Quais cookies coletamos?</strong></p>
          <ul>
            <li>• Analytics e customização</li>
          </ul>
          <p>Os cookies analíticos e customização coletam dados estatísticos anônimos com a finalidade de analisar a
            utilização do site e seu respectivo desempenho, permitindo aperfeiçoar o seu funcionamento bem como
            auxiliá-lo em alguns campos. Mostram, por exemplo, quais são as páginas mais visitadas no site, permitem
            preenchimento automático de algumas informações e ajudam a registrar quaisquer dificuldades que os usuários
            sintam na navegação.</p>


          <p><strong>E não é perigoso ter minhas informações armazenadas?</strong></p>
          <p>Não é perigoso. Todas as suas informações coletadas em nosso site, como número de conta, senhas e outras,
            são protegidas por nosso sistema de segurança certificado.</p>

          <p><strong>Não gosto de cookies, como faço para desativá-los?</strong></p>
          <p>É possível restringir, bloquear ou excluir os cookies por meio das preferências do seu navegador. Em cada
            navegador, a operação é diferente. Para saber como gerenciar, veja as definições sobre cookies em cada um
            dos navegadores: </p>
          <p>Microsoft Edge / Firefox / Google Chrome / Safari </p>
          <p>Não se esqueça que os cookies permitem que você tenha uma melhor experiência em nossos sites e, sem eles,
            sua navegação pode se tornar limitada e algumas funcionalidades dos sites podem ficar comprometidas.</p>



          <h6><strong>Disposições Finais</strong></h6>
          <p>Alertamos que esta Política de Cookies poderá ser modificada a qualquer tempo pelo FIDC Ipanema. As
            mudanças entrarão em vigor logo após a publicação, e você será avisado. Ao continuar a navegação, você
            concorda com as alterações. </p>
          <p>Se qualquer alteração exigir seu consentimento, faremos uma solicitação para que você forneça esse novo
            consentimento. Aconselhamos que visite nossa Política de Cookies com frequência para manter-se informado
            sobre quaisquer atualizações ou alterações.</p>
          <p>Em caso de dúvidas sobre esta Política de Cookies, entre em contato conosco no endereço de e-mail <a
              href="mailto:dpo@negocieipanema.com.br">dpo@negocieipanema.com.br</a>.</p>
        </div>
      </div>
    </div>
  </div>
</ng-container>