import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AccountService } from './../../../services/account.service';

@Component({
  selector: 'app-confirm-user-account',
  templateUrl: './confirm-user-account.component.html',
  styleUrls: ['./confirm-user-account.component.scss'],
})
export class ConfirmUserAccountComponent implements OnInit {
  confirmAccountForm: FormGroup;
  @Input() username: string;
  @Input() phoneNumber: string;
  public loading = false;

  constructor(
    private activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private accountService: AccountService
  ) {}

  ngOnInit(): void {
    this.confirmAccountForm = this.fb.group({
      code: [null, Validators.required],
    });
  }

  close() {
    this.activeModal.close('cancel');
  }

  confirm() {
    this.accountService
      .validateSms({
        Username: this.username,
        ConfirmationCode: this.confirmAccountForm.controls.code.value,
      })
      .subscribe(
        (data: any) => {
          this.loading = false;
          this.activeModal.close('confirm');
        },
        (err) => {
          this.loading = false;
        }
      );
  }

  getInputValueCode(ev: any): void {
    this.confirmAccountForm.controls[ev.name].setValue(ev.value);
  }
}
