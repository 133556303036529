<app-loading [show]="loading"></app-loading>
<div class="container debt-page" *ngIf="debt">
  <div class="d-flex">
    <div class="col-12 pl-0 back-button">
      <button *ngIf="!(showContent('DEBT_AGREEMENT') || showContent('ARRANGEMENT_IN_DEBT'))"
        class="btn btn-light btn-rollback" id="debt-btn-rollback" routerLink="/painel">
        <em class="fa fa-arrow-left fa-1x"></em>
        {{ 'BUTTONS.RETURN' | translate }}
      </button>

      <button *ngIf="showContent('DEBT_AGREEMENT') || showContent('ARRANGEMENT_IN_DEBT')"
        class="btn btn-light btn-rollback" id="debt-btn-rollback" routerLink="/painel/acordos">
        <em class="fa fa-arrow-left fa-1x"></em>
        {{ 'BUTTONS.RETURN' | translate }}
      </button>
    </div>
  </div>
  <div class="d-flex flex-wrap">
    <div class="col-lg-2 col-sm-12 col-xs-12 detail-debt-wrapper" [ngClass]="{'col-lg-3': debt?.HasLegalClaim}">
      <h6 class="title">{{ 'DEBT.DETAIL-TITLE' | translate }}</h6>
      <div class="portfolio-avatar d-flex justify-content-center align-items-center"
        [ngClass]="{'placeholder' : !debt?.ImageUri}">
        <img *ngIf="debt.ImageUri" [src]="debt?.ImageUri" alt="Logo dívida" rel="preload" />
        <span *ngIf="!debt.ImageUri"> {{debt?.PortfolioName.slice(0, 1)}}</span>
      </div>
      <div class="d-flex align-items-center justify-content-between">
        <h5 class="company-name">{{ debt?.PortfolioName }}</h5>
        <button type="button" class="hide-if-desktop info-colapse" (click)="collapse.toggle()"
          [attr.aria-expanded]="!isCollapsed" aria-controls="collapse">
          <span *ngIf="!isCollapsed">
            {{ 'DEBT.NO-COLLAPSED' | translate }}
          </span>
          <span *ngIf="isCollapsed">
            {{ 'DEBT.COLLAPSED' | translate }}
          </span>
        </button>
      </div>

      <div id="collapse" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
        <div class="collapse-ngb">
          <div class="item">
            <strong>{{ 'DEBT.CONTRACT-NUMBER' | translate }}</strong> <br />
            <small>{{ debt?.OriginalDebtNumber }}</small>
          </div>

          <div class="item">
            <strong>{{ 'DEBT.ORIGIN' | translate }}</strong> <br />
            <small>{{ debt?.ProductName }}</small>
          </div>

          <div class="item">
            <strong>{{ 'DEBT.ORIGIN-DATE' | translate }}</strong> <br />
            <small>{{ debt?.FirstDefaultDate | date: 'dd/MM/yyyy' }}</small>
          </div>

          <div class="item">
            <strong>{{ 'DEBT.LAG-TIME' | translate }}</strong> <br />
            <small>{{ delayDebt }}</small>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-12 col-xs-12 rightside pl-4"
      [ngClass]="{'col-lg-9': debt?.HasLegalClaim, 'col-lg-10' : !debt?.HasLegalClaim}">
      <h6 class="title pl-0" style="font-size: 25px;">
        {{ getRightSideTitle() | translate }}
      </h6>

      <ng-container *ngIf="debt?.HasLegalClaim">
        <div class="claim-warning">
          Esta dívida possui processo júridico e não poderá ser negociada no momento. Para mais informações, entre em
          contato pelo o telefone <span>{{'HOME.CHANNELS.0800' | translate}}</span>.
        </div>
      </ng-container>

      <ng-container *ngIf="!debt?.HasLegalClaim">
        <!-- Forma de pagamento -->
        <div class="row">
          <div class="col-12 col-md-6 col-lg-6 p-1 mb-3 mt-3 "
            *ngIf="showContent('MAKE_PROPOSAL') || showContent('DEBT_PROPOSAL')">
            <div class="input-select-ipanema"
              [ngClass]="{ 'has-error': isSubmitted && (!paymentMethod || paymentMethod == '0') }">
              <label for="paymethod">{{
                'DEBT.PROPOSAL.PAYM-METHOD' | translate
                }}</label>
              <select id="paymethod" [(ngModel)]="paymentMethod" (change)="changePayMethod($event)">
                <option [value]="0" selected>Selecione</option>
                <option [value]="pm.value" *ngFor="let pm of methods">
                  {{ pm.label }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-6 p-1"
            *ngIf="showContent('MAKE_PROPOSAL') || showContent('DEBT_PROPOSAL')">
            <form [formGroup]="coupon">
              <div class="input-group coupon-input">
                <input type="text" class="form-control" id="cupom" placeholder="Cupom de desconto"
                  formControlName="CouponCode" aria-label="Coupon" aria-describedby="basic-addon2">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary coupon-btn" *ngIf="!isValid" type="button"
                    (click)="validateCoupon()">Aplicar</button>
                  <button class="btn btn-outline-secondary coupon-btn" *ngIf="isValid" type="button"
                    (click)="clearCoupon()">Remover</button>
                </div>
              </div>
            </form>

          </div>
        </div>


        <p class="disclaimer" *ngIf="showContent('MAKE_PROPOSAL') || showContent('DEBT_PROPOSAL')">
          <em class="fa fa-info-circle"></em> {{ 'DEBT.PROPOSAL.PAYM-METHOD-INFO' | translate }}
        </p>

        <!-- Lista de opções de acordos (a vista, parcelado e personalizado)-->
        <app-debt-proposal [couponName]="coupon.value.CouponCode" [debt]="debt" [paymentMethod]="paymentMethod"
          (makeYourProposal)="MakeProposal($event)" *ngIf="showContent('DEBT_PROPOSAL')"
          (isSubmitted)="setSubmitted($event)"></app-debt-proposal>

        <!-- Faça sua proposta passo 2 -->
        <app-make-your-proposal [debt]="debt" [paymentMethod]="paymentMethod"
          (hideMakeYourProposal)="MakeProposal($event)" *ngIf="showContent('MAKE_PROPOSAL')"
          (isSubmitted)="setSubmitted($event)"></app-make-your-proposal>


        <!-- Acordo -->
        <app-debt-agreement [arrangement]="arrangement" *ngIf="arrangement && showContent('DEBT_AGREEMENT')"
          (updateArrangement)="updateArrangement($event)" [installmentNumber]="installmentNumber"></app-debt-agreement>

        <app-arrangement-in-debt [arrangement]="arrangement" *ngIf="showContent('ARRANGEMENT_IN_DEBT')">
        </app-arrangement-in-debt>
      </ng-container>
    </div>
    <!--
    <div class="col-12 mt-4" >
      <div class="emdia-banner">
        <div class="row">
          <div class="col-sm-7 col-md-8 col-lg-8 col-xl-9 align-self-center col-text">
            <h3 class="title-emdia">{{ 'MYSPACE.EMDIA.MSG' | translate }}</h3>
          </div>
          <div class="col-sm-5 col-md-4 col-lg-4 col-xl-3 align-self-center align-right col-btn">
            <div class="row">
              <a target="_blank"
                href="https://euemdia.com.br/?utm_source=portalipanema&utm_medium=banner&utm_campaign=jornada"><button
                  type="button" class="button primary">{{'MYSPACE.EMDIA.BUTTON' | translate}}
                </button></a>
            </div>
            <div class="row">
              <img loading="lazy" class="logo-img" src="assets/images/logoEmDia.png" alt="video" rel="preload" />
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</div>