import { Component, Input } from '@angular/core';

@Component({
  templateUrl: 'dropdown.component.html',
  selector: 'app-dropdown',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent {
  @Input() isPrimary = false;

  toggled = false;

  click(toggled) {
    this.toggled = toggled;
  }

  blur() {
    const that = this;
    that.toggled = false;
  }
}
