<div class="modal-wrapper">
  <div class="modal-header">
    <p>{{ 'MODAL-USER-MIGRATION.TITLE' | translate }}</p>
  </div>
  <div class="modal-body">
    <div class="d-flex justify-content-between mt-5">
      <p>{{ 'MODAL-USER-MIGRATION.DESCRIPTION' | translate }}</p>
    </div>
    <div class="form-check">
      <input
        class="form-check-input"
        type="checkbox"
        value=""
        id="flexCheckChecked"
        (click)="check($event)"
      />
      <label class="form-check-label" for="flexCheckChecked">
        <p>{{ 'MODAL-USER-MIGRATION.CHECKBOX-LABEL' | translate }}</p>
      </label>
    </div>
  </div>
  <div class="modal-footer">
    <button
      class="button primary btn-login"
      type="button"
      [disabled]="!canClose"
      (click)="close()"
    >
      {{ 'MODAL-USER-MIGRATION.CLOSE-BUTTON' | translate }}
    </button>
  </div>
</div>
