<div class="debt-card-wrapper mt-5">
  <div class="
      debt-card-header
      mb-2
      d-flex
      align-items-start
      justify-content-between
    ">
    <h6>
      {{ 'ARRANGEMENT-IN-DEBT.TITLE' | translate }}
    </h6>
    <app-status-debt *ngIf="arrangement.ArrangementStatus" [state]="arrangement['ArrangementStatus']"></app-status-debt>
  </div>
  <div class="debt-wrapper">
    <p>
      {{ 'ARRANGEMENT-IN-DEBT.LABELS.REQUEST-DATE' | translate }}
      <strong>
        {{ arrangement.ArrangementDate | date: 'dd/MM/yyyy' }}
      </strong>
    </p>
    <p>
      {{ 'ARRANGEMENT-IN-DEBT.LABELS.PROPOSAL-VALUE' | translate }}
      <strong>
        {{ arrangement.ArrangementAmount | currency: 'BRL':'R$':'1.2-2' }}
      </strong>
    </p>
    <p *ngIf="arrangement?.ArrangementDescription">
      {{ 'ARRANGEMENT-IN-DEBT.LABELS.PAYMENT' | translate }}
      <strong>
        {{ arrangement.ArrangementDescription }}
      </strong>
    </p>
    <p *ngIf="arrangement?.DefaultPaymentMethodAlias">
      {{ 'ARRANGEMENT-IN-DEBT.LABELS.PAYMENT-METHOD' | translate }}
      <strong>
        {{ arrangement.DefaultPaymentMethodAlias }}
      </strong>
    </p>
    <p>
      {{ 'ARRANGEMENT-IN-DEBT.LABELS.DUE-DATE' | translate }}
      <strong>
        {{ getExpirationDay(arrangement?.FirstInstallmentDueDate) }}
        {{ 'ARRANGEMENT-IN-DEBT.LABELS.INFO-DUE-DATE' | translate }}
      </strong>
    </p>
  </div>
</div>

<div class="info-wrapper d-flex align-items-center justify-content-center mt-3">
  <img src="./assets/images/warning.png" alt="icone de alerta" rel="preload" />
  <span class="ml-3">
    {{ 'ARRANGEMENT-IN-DEBT.iNFO' | translate }}
  </span>
</div>