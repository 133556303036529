<ng-container [ngSwitch]="type">
  <div class="box-input-wrapper" *ngSwitchCase="'username'" style="{{visible ? '' : 'display: none;'}}">
    <div class="box-input-container {{variant}}" [ngClass]="{ 'has-error': hasError }">
      <label [for]="id" *ngIf="variant != 'santander'">{{ label }}</label>
      <input [aria-label]="id" type="text" mask="CPF_CNPJ" [id]="id" [name]="controlName" [placeholder]="placeholder"
        data-evt-collect="true" data-evt-category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38"
        data-evt-type="4f117429-4a95-4bae-b603-8dd3dc74ccee" data-evt-context-id="cpf_cnpj"
        data-evt-element-id="CPF Input" data-evt-context-dynamic="true" autocomplete="username"
        (input)="onInput($event)" />
    </div>
    <!-- <label [for]="id" *ngIf="variant == 'santander'" class="{{variant}}">{{ label }}</label> -->
    <div class="hint-block" *ngIf="hint">{{ hint }}</div>
  </div>

  <div class="box-input-wrapper" *ngSwitchCase="'identification'" style="{{visible ? '' : 'display: none;'}}">
    <div class="box-input-container {{variant}}" [ngClass]="{ 'has-error': hasError }">
      <label [for]="id" *ngIf="variant != 'santander'">{{ label }}</label>
      <input [aria-label]="id" type="text" mask="CPF_CNPJ" [id]="id" [name]="controlName" [(ngModel)]="value"
        [placeholder]="placeholder" data-evt-collect="true" data-evt-category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38"
        data-evt-type="4f117429-4a95-4bae-b603-8dd3dc74ccee" data-evt-context-id="cpf_cnpj"
        data-evt-element-id="CPF Input" data-evt-context-dynamic="true" (input)="onInput($event)"
        autocomplete="new-id" />
    </div>
    <!-- <label [for]="id" *ngIf="variant == 'santander'" class="{{variant}}">{{ label }}</label> -->
    <div class="hint-block" *ngIf="hint">{{ hint }}</div>
  </div>

  <div class="box-input-wrapper" *ngSwitchCase="'password'" style="{{visible ? '' : 'display: none;'}}">
    <div class="box-input-container {{variant}}" [ngClass]="{ 'has-error': hasError }">
      <label [for]="id" *ngIf="variant != 'santander'">{{ label }}</label>
      <input [aria-label]="id" type="password" [id]="id" [name]="controlName" [placeholder]="placeholder"
        (input)="onInput($event)" [value]="currentPassword ? currentPassword : null" [autocomplete]="autocomplete" />
      <em class="fa fa-eye-slash btn-toggle-password" (click)="togglePasswordVisibility()"
        (keypress)="togglePasswordVisibility()"></em>
    </div>
    <!-- <label [for]="id" *ngIf="variant == 'santander'" class="{{variant}}">{{ label }}</label> -->
    <div class="hint-block {{variant}}" *ngIf="hint">{{ hint }}</div>
  </div>

  <div class="box-input-wrapper" *ngSwitchCase="'visible-password'" style="{{visible ? '' : 'display: none;'}}">
    <div class="box-input-container {{variant}}" [ngClass]="{ 'has-error': hasError }">
      <label [for]="id" *ngIf="variant != 'santander'">{{ label }}</label>
      <input [aria-label]="id" type="text" [id]="id" [name]="controlName" [placeholder]="placeholder"
        (input)="onInput($event)" [value]="currentPassword ? currentPassword : null" />
      <em class="fa fa-eye btn-toggle-password" (click)="togglePasswordVisibility()"
        (keypress)="togglePasswordVisibility()"></em>
    </div>
    <!-- <label [for]="id" *ngIf="variant == 'santander'" class="{{variant}}">{{ label }}</label> -->
    <div class="hint-block {{variant}}" *ngIf="hint">{{ hint }}</div>
  </div>

  <div class="box-input-wrapper" *ngSwitchCase="'text'" style="{{visible ? '' : 'display: none;'}}">
    <div class="box-input-container {{variant}}" [ngClass]="{ 'has-error': hasError }">
      <label [for]="id" *ngIf="variant != 'santander'">{{ label }}</label>
      <input [aria-label]="id" type="text" [id]="id" [name]="controlName" [value]="value" [placeholder]="placeholder"
        (input)="onInput($event)" />
    </div>
    <!-- <label [for]="id" *ngIf="variant == 'santander'" class="{{variant}}">{{ label }}</label> -->
    <div class="hint-block" *ngIf="hint">{{ hint }}</div>
  </div>

  <div class="box-input-wrapper" *ngSwitchCase="'sms-code'" style="{{visible ? '' : 'display: none;'}}">
    <div class="box-input-container {{variant}}" [ngClass]="{ 'has-error': hasError }">
      <label [for]="id" *ngIf="variant != 'santander'">{{ label }}</label>
      <input [aria-label]="id" type="text" [id]="id" mask="000000" [name]="controlName" [value]="value"
        [placeholder]="placeholder" (input)="onInput($event)" autocomplete="off" />
    </div>
    <!-- <label [for]="id" *ngIf="variant == 'santander'" class="{{variant}}">{{ label }}</label> -->
    <div class="hint-block" *ngIf="hint">{{ hint }}</div>
  </div>

  <div class="box-input-wrapper" *ngSwitchCase="'email'" style="{{visible ? '' : 'display: none;'}}">
    <div class="box-input-container {{variant}}" [ngClass]="{ 'has-error': hasError }">
      <label [for]="id" *ngIf="variant != 'santander'">{{ label }}</label>
      <input [aria-label]="id" type="email" [id]="id" [name]="controlName" [value]="value" [placeholder]="placeholder"
        [disabled]="disabled" (input)="onInput($event)" data-evt-collect="true"
        data-evt-category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38" data-evt-type="4f117429-4a95-4bae-b603-8dd3dc74ccee"
        data-evt-context-id="email" data-evt-element-id="Email Input" data-evt-context-dynamic="true" />
    </div>
    <!-- <label [for]="id" *ngIf="variant == 'santander'" class="{{variant}}">{{ label }}</label> -->
    <div class="hint-block" *ngIf="hint">{{ hint }}</div>
  </div>

  <div class="box-input-wrapper" *ngSwitchCase="'phone'" style="{{visible ? '' : 'display: none;'}}">
    <div class="box-input-container {{variant}}" [ngClass]="{ 'has-error': hasError }">
      <label [for]="id" *ngIf="variant != 'santander'">{{ label }}</label>
      <input [aria-label]="id" type="text" mask="(00) 00000-0000" [id]="id" [name]="controlName" [value]="value"
        [dropSpecialCharacters]="false" [placeholder]="placeholder" (input)="onInput($event)" data-evt-collect="true"
        data-evt-category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38" data-evt-type="4f117429-4a95-4bae-b603-8dd3dc74ccee"
        data-evt-context-id="celular" data-evt-element-id="Celular Input" data-evt-context-dynamic="true" />
    </div>
    <!-- <label [for]="id" *ngIf="variant == 'santander'" class="{{variant}}">{{ label }}</label> -->
    <div class="hint-block" *ngIf="hint">{{ hint }}</div>
  </div>

  <div class="box-input-wrapper" *ngSwitchCase="'checkbox'" style="{{visible ? '' : 'display: none;'}}">
    <div class="box-input-container-checkbox" [ngClass]="{ 'has-error': hasError }">
      <input [aria-label]="id" type="checkbox" [id]="id" [name]="controlName" [placeholder]="placeholder"
        (input)="onInput($event)" />
    </div>
    <div class="hint-block" *ngIf="hint">{{ hint }}</div>
  </div>
</ng-container>