<app-loading [show]="isLoading"></app-loading>

<div class="card-wrapper" [ngClass]="{'turn' : isQRCodeVisible}">
  <div class="flip-card-inner">

    <!-- front card -->
    <div class="card">
      <!-- Acordos -->
      <div class="card-body" *ngIf="debt.ArrangementStatus">
        <div>
          <div class="debt-profile d-flex align-items-center justify-content-between">
            <div class="portfolio d-flex align-items-center">
              <div class="portfolio-avatar d-flex justify-content-center align-items-center"
                [ngClass]="{'placeholder' : !debt?.ImageUri}">
                <img *ngIf="debt?.ImageUri" [src]="debt?.ImageUri" alt="Portfolio logo" rel="preload" />
                <span *ngIf="!debt?.ImageUri"> {{debt?.PortfolioName.slice(0, 1)}}</span>
              </div>
              <h6 class="portfolio-name card-title">{{ debt?.PortfolioName }}</h6>
            </div>

            <app-status-debt *ngIf="debt.ArrangementStatus" [state]="debt['ArrangementStatus']"
              (stateValue)="getStateValue($event)"></app-status-debt>
          </div>

          <div class="debt-item">
            <small class="item-label" *ngIf="debt?.DebtTotalBalance">{{ 'DEBT-CARD.LIST-ITEMS.DEBT-TOTAL-BALANCE' |
              translate }}:</small>
            <small class="item-label" *ngIf="debt?.ArrangementAmount">{{ 'DEBT-CARD.LIST-ITEMS.ARRANG-AMOUNT' |
              translate }}:</small>
            <strong class="item-value">{{
              debt?.DebtTotalBalance | currency: 'BRL':'R$':'1.2-2'
              }}</strong>
            <strong class="item-value">{{
              debt?.ArrangementAmount | currency: 'BRL':'R$':'1.2-2'
              }}</strong>
          </div>

          <div class="debt-item">
            <small class="item-label">{{ 'DEBT-CARD.LIST-ITEMS.PRODUCT-NAME' | translate }}:</small>
            <strong class="item-value">{{
              debt?.PortfolioName ? debt?.PortfolioName : debt?.ProductName
              }}</strong>
          </div>

          <div class="debt-item">
            <small class="item-label">{{ 'DEBT-CARD.LIST-ITEMS.OPEN-DATE' | translate }}:</small>
            <strong class="item-value" *ngIf="debt?.ArrangementDate">{{
              debt?.ArrangementDate | date: 'dd/MM/yyyy'
              }}</strong>
            <strong class="item-value" *ngIf="debt?.OpenDate">{{
              debt?.OpenDate | date: 'dd/MM/yyyy'
              }}</strong>
          </div>

          <div class="debt-item" *ngIf="debt.ArrangementStatus != 'PAY_OFF'">
            <small class="item-label">{{ 'DEBT-CARD.LIST-ITEMS.INSTALLMENT' | translate }}:</small>
            <strong class="item-value">{{
              debt?.CurrInstallment ? debt?.CurrInstallment : debt?.CurrInstallment
              }}</strong>
          </div>

          <!-- Se for pix, não precisa ter billetLine neste momento -->
          <div *ngIf="debt.PaymentMethod == 3 && debt.BilletLine != null && debt.BilletLine != ''">
            <div>
              <p class="label-text m-0 pt-2">
                <small class="label-code bold">{{ 'ARRANGEMENTS.DETAILS.BILLET-LINE' | translate
                  }}</small>

                <span class="clipboard" *ngIf="!coppied" (click)="copyToClipboard(debt.BilletLine, debt.PaymentMethod)">
                  <img src="/assets/images/fi_copy.svg" alt="clipboard" class="clipboard-icon" rel="preload" />
                  <p id="btn_copy_barcode" data-evt-collect="true" *ngIf="debt.PaymentMethod == 3"
                    data-evt-category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38"
                    data-evt-type="5e48115d-6ada-4ff5-a140-09b9e963b0b1" attr.data-evt-context-id="{{ customerInfo }}"
                    data-evt-element-id="Copiar código boleto" class="clipboard-label">
                    {{ 'ARRANGEMENTS.DETAILS.COPY' | translate }}
                  </p>
                </span>
                <span class="clipboard" *ngIf="coppied" (click)="sendGTMButtonCopy()">
                  <p class="clipboard-label coppied">
                    {{ 'ARRANGEMENTS.DETAILS.COPPIED' | translate }}
                  </p>
                </span>
              </p>
              <div ngClass="{'opened': debt.opened}">
                <small class="value-text billet">{{ debt.BilletLine }} </small>
              </div>
            </div>
          </div>
        </div>
        <div class="row row-btns align-items-center justify-content-between mt-2">
          <div class="col-4">
            <a class="link-debt-detail" data-evt-collect="true" data-evt-category="ca7650f8-eef8-43f8-a504-582889bb0748"
              data-evt-type="5e48115d-6ada-4ff5-a140-09b9e963b0b1" attr.data-evt-context-id="{{ customerInfo }}"
              data-evt-element-id="Ver detalhes" *ngIf="debt.ArrangementId"
              [routerLink]="['/painel/acordo/', debt.ArrangementId]">{{ 'DEBT-CARD.BTN-DEBT-DETAIL' | translate }}</a>
          </div>

          <div class="col-6 col-md-5 d-flex justify-content-end" *ngIf="stateValue === 'QUITADA'">
            <button class="primary" id="btn_letter" data-evt-collect="true"
              data-evt-category="ca7650f8-eef8-43f8-a504-582889bb0748"
              data-evt-type="18bde62f-bc03-4c32-96fe-bf58df7b81c4" attr.data-evt-context-id="{{ customerInfo }}"
              data-evt-element-id="Carta de Quitação" (click)="openModalDischarge()">
              {{ 'DEBT-CARD.BTN-DEBT-DISCHARGE' | translate }}
            </button>
          </div>

          <div class="col-6 col-md-5 d-flex justify-content-end" *ngIf="stateValue === 'EM ANDAMENTO'">
            <button class="primary" data-evt-collect="true" data-evt-category="ca7650f8-eef8-43f8-a504-582889bb0748"
              data-evt-type="18bde62f-bc03-4c32-96fe-bf58df7b81c4" attr.data-evt-context-id="{{ customerInfo }}"
              data-evt-element-id="Gerar último boleto" (click)="generateLastBill()"
              *ngIf="debt.PaymentMethod == 3 && debt.BilletLine">
              {{ 'DEBT-CARD.BTN-DEBT-BILL' | translate }}
            </button>
            <button class="primary" data-evt-collect="true" data-evt-category="ca7650f8-eef8-43f8-a504-582889bb0748"
              data-evt-type="18bde62f-bc03-4c32-96fe-bf58df7b81c4" attr.data-evt-context-id="{{ customerInfo }}"
              data-evt-element-id="Ver QR Code" (click)="showQRCode(true)"
              *ngIf="debt.PaymentMethod == 11 && debt.BilletLine">
              {{ 'DEBT-CARD.BTN-DEBT-BILL-PIX' | translate }}
            </button>
          </div>
        </div>
      </div>

      <!-- Dívidas -->
      <div class="card-body" *ngIf="debt.DebtStatus">
        <div class="debt-profile d-flex align-items-center justify-content-between">
          <div class="portfolio d-flex align-items-center">
            <div class="portfolio-avatar d-flex justify-content-center align-items-center"
              [ngClass]="{'placeholder' : !debt?.ImageUri}">
              <img *ngIf="debt?.ImageUri" [src]="debt?.ImageUri" alt="Portfolio logo" rel="preload" />
              <span *ngIf="!debt?.ImageUri"> {{debt?.PortfolioName.slice(0, 1)}}</span>
            </div>
            <h6 class="portfolio-name card-title">{{ debt?.PortfolioName }}</h6>
          </div>
          <div class="legal-claim" *ngIf="debt.HasLegalClaim" ngbTooltip="Esta dívida possui processo ativo"
            #tooltip="ngbTooltip">
            <i class="fa fa-solid fa-gavel fa-2x"></i>
          </div>
          <app-status-debt *ngIf="!debt.HasLegalClaim && debt.DebtStatus" [state]="debt['DebtStatus']"
            (stateValue)="getStateValue($event)">
          </app-status-debt>
        </div>

        <div class="row">
          <div class="col">
            <small class="label">{{ 'DEBT-CARD.LIST-ITEMS.DEBT-ORIGINAL-BALANCE' | translate }}</small>
            <p class="m-0 currency-value-secondary">
              {{ debt?.CurrentBalance | currency: 'BRL':'R$':'1.2-2' }}
            </p>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col">
            <h6>{{ 'DEBT-CARD.LIST-ITEMS.DEBT-TOTAL-BALANCE' | translate }}</h6>
            <p class="value currency-value m-0">
              {{ debt?.DebtTotalBalance | currency: 'BRL':'R$':'1.2-2' }}
            </p>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col">
            <p class="m-0">
              {{ debt?.ProductName }}
            </p>
          </div>
          <div class="col text-right" *ngIf="!debt.HasLegalClaim && debt.DebtStatus">
            <button class="button primary" data-evt-collect="true" (click)="trackCart()"
              data-evt-category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38"
              data-evt-type="18bde62f-bc03-4c32-96fe-bf58df7b81c4" attr.data-evt-context-id="{{ customerInfo }}"
              data-evt-element-id="Ver desconto" [routerLink]="['/painel/divida/', debt.DebtId]">{{
              'DEBT-CARD.CHECK-DISCOUNT' | translate }}</button>
          </div>
          <div class="pr-4 text-right" *ngIf="debt.HasLegalClaim">
            <a class="link-debt-detail" data-evt-collect="true" data-evt-category="ca7650f8-eef8-43f8-a504-582889bb0748"
              data-evt-type="5e48115d-6ada-4ff5-a140-09b9e963b0b1" attr.data-evt-context-id="{{ customerInfo }}"
              data-evt-element-id="Ver detalhes" [routerLink]="['/painel/divida/', debt.DebtId]">{{
              'DEBT-CARD.BTN-DEBT-DETAIL' | translate }}</a>
          </div>
        </div>

        <!-- <div class="debt-item">
      <small class="item-label">{{ 'DEBT-CARD.LIST-ITEMS.OPEN-DATE' | translate }}:</small>
      <strong class="item-value" *ngIf="debt?.ArrangementDate">{{
        debt?.ArrangementDate | date: 'dd/MM/yyyy'
        }}</strong>
      <strong class="item-value" *ngIf="debt?.OpenDate">{{
        debt?.OpenDate | date: 'dd/MM/yyyy'
        }}</strong>
    </div> -->
      </div>
    </div>

    <!-- back card -->
    <div class="card card-back" *ngIf="debt.ArrangementStatus && debt.PaymentMethod == 11">
      <div class="row ml-0 mr-0" style="height: 100%;">
        <div class="col pl-4 pr-0">
          <div class="row pt-4 pb-4 text-left">
            <div class="col-12" style="height: 38px;">
              <button class="btn btn-rollback" (click)="showQRCode(false)" type="button">
                <em class="fa fa-arrow-left fa-1x"></em>
                {{ 'BUTTONS.RETURN' | translate }}
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div>
                <p class="label-text text-left m-0">
                  <small class="label-code bold">{{ 'ARRANGEMENTS.DETAILS.BILLET-PIX' | translate
                    }}</small>

                  <span class="clipboard" *ngIf="!coppied"
                    (click)="copyToClipboard(debt.BilletLine, debt.PaymentMethod)">
                    <img src="/assets/images/fi_copy.svg" alt="clipboard" class="clipboard-icon" rel="preload" />
                    <p id="btn_copy_barcode" data-evt-collect="true" *ngIf="debt.PaymentMethod == 11"
                      data-evt-category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38"
                      data-evt-type="5e48115d-6ada-4ff5-a140-09b9e963b0b1" attr.data-evt-context-id="{{ customerInfo }}"
                      data-evt-element-id="Copiar código PIX" class="clipboard-label">
                      {{ 'ARRANGEMENTS.DETAILS.COPY' | translate }}
                    </p>
                  </span>
                  <span class="clipboard" *ngIf="coppied" (click)="sendGTMButtonCopy()">
                    <p class="clipboard-label coppied">
                      {{ 'ARRANGEMENTS.DETAILS.COPPIED' | translate }}
                    </p>
                  </span>
                </p>
                <div class="code-expand-container" [ngClass]="{'opened': debt.opened}"
                  (click)="!debt.opened && (debt.opened = !debt.opened)" *ngIf="debt?.QRCode">
                  <small class="value-text billet pix-code">{{ debt.BilletLine }} </small>
                </div>
                <small class="expand-button" (click)="debt.opened = !debt.opened">
                  Ver <ng-container *ngIf="debt.opened">menos</ng-container>
                  <ng-container *ngIf="!debt.opened">mais</ng-container>
                </small>
              </div>
            </div>
          </div>
        </div>
        <div class="col col-sm-5">
          <div class="row pt-4 pb-3 ">
            <div class="col-12 align-self-center"
              style="height: 38px; display: flex;flex-direction: column;justify-content: center;">
              <app-timer *ngIf="isQRCodeVisible" [time]="180000" (notify)="showQRCode($event)"
                (notifyEach)="checkPayment($event)"></app-timer>
            </div>
          </div>
          <div class="row">
            <div class="col align-self-center text-right ">
              <img class="image-company qr-code" *ngIf="debt?.QRCode" [src]="debt.QRCode" alt="QRCode PIX"
                rel="preload" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>