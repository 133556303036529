import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-pending-proposal',
  templateUrl: './confirm-pending-proposal.component.html',
  styleUrls: ['./confirm-pending-proposal.component.scss']
})
export class ConfirmPendingProposalComponent {

  constructor(
    private activeModal: NgbActiveModal
  ) { }

  close() {
    this.activeModal.close('close');
  }

}
