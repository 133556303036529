import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from '../../../services/authentication.service';
import { PaymentMethod } from '../../models/payment-method.constant';


@Component({
  selector: 'app-confirm-custom-proposal',
  templateUrl: './confirm-custom-proposal.component.html',
  styleUrls: ['./confirm-custom-proposal.component.scss']
})
export class ConfirmCustomProposalComponent {

  @Input() proposal: any;
  firstInstallmentDate: any;
  customerInfo: any = {};

  @Input() currentBalance: number;
  @Input() paymentMethod: PaymentMethod;

  constructor(
    public authenticationService: AuthenticationService,
    private activeModal: NgbActiveModal
  ) {

    this.authenticationService.getCustomerInfo().then((rtn) => {
      this.customerInfo = rtn
    });

  }

  getTotalValue() {

    if (this.proposal) {
      this.firstInstallmentDate = this.customDateAdapter(this.proposal.FirstInstallmentDate);
      return this.proposal.DownPaymentAmount +
        (this.proposal.InstallmentAmount * this.proposal.TotalInstallments);
    }

    return 0;
  }

  customDateAdapter = (value) => {
    return (
      value.year +
      '/' +
      ('0' + value.month).slice(-2) +
      '/' +
      ('0' + value.day).slice(-2)
    );
  }

  close() {
    this.activeModal.close('cancel');
  }

  confirm() {
    this.activeModal.close('confirm');
  }

}
