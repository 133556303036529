<app-loading [show]="loading"></app-loading>
<div class="debt-proposal" *ngIf="negotiations">
  <div class="row cards-wrapper">
    <div class="col offer-proposal in-cash">
      <div class="content-offer">
        <div>
          <h3 class="text-center">{{ 'DEBT.PROPOSAL.IN-CASH' | translate }}</h3>
          <div class="row">
            <div class="col text-center info-success mb-3 mt-3">
              {{ 'DEBT.PROPOSAL.PRE-APPROVED-PROPOSAL-1' | translate }}
              {{
                negotiations[0].arrangementDiscount
                  | currency: 'BRL':'R$':'1.2-2'
              }}
              ({{ negotiations[0].discountRate | percent: '2.2' }})
            </div>
          </div>
          <div class="row">
            <div class="col text-center mb-2 mt-2 value">
              {{ 'DEBT.VALUE' | translate }}
            </div>
          </div>
          <div class="row">
            <div class="col text-center mb-2 mt-2">
              <s>{{
                negotiations[0].debtTotalBalance | currency: 'BRL':'R$':'1.2-2'
              }}</s>
            </div>
          </div>
          <div class="row">
            <div class="col text-center mb-2 mt-3 value">
              {{ 'DEBT.OFFER-VALUE' | translate }}
            </div>
          </div>
          <div class="row">
            <div class="col text-center mb-3 mt-2 new-value">
              {{
                negotiations[0].arrangementAmount | currency: 'BRL':'R$':'1.2-2'
              }}
            </div>
          </div>
          <div class="row">
            <div
              class="col text-center mb-3 mt-3 input-control-ipanema"
              [ngClass]="{
                'has-error':
                  formSubmitted && !endDateInCash && typeSubmitted == 1
              }"
            >
              <div class="label-input" (click)="typeSubmitted = 2">
                <label for="end-date-in-cash">{{
                  'DEBT.PROPOSAL.END-DATE' | translate
                }}</label>
                <input
                  id="end-date-in-cash"
                  class="date"
                  placeholder="{{ dateToday | date: 'dd/MM/yyyy' }}"
                  [markDisabled]="markDisabled"
                  name="dp"
                  [(ngModel)]="endDateInCash"
                  ngbDatepicker
                  #d="ngbDatepicker"
                  [placement]="'top'"
                  [minDate]="minDate"
                  [maxDate]="maxDate"
                  readonly
                  (dateSelect)="updateSelectedDate($event)"
                  (click)="aVistaInputGTM()"
                />
              </div>
              <div
                class="input-group-append d-flex"
                (click)="aceitarVistaBtnGTM(); typeSubmitted = 1"
              >
                <button
                  class="btn btn-outline-secondary btn-sm"
                  (click)="d.toggle()"
                  type="button"
                >
                  <em class="fa fa-calendar"></em>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col text-center mt-3">
            <button
              class="button primary fechar-acordo {{ debt?.PortfolioName }}-{{
                debt?.PortfolioId
              }}"
              (click)="accept(negotiations[0], endDateInCash, 1)"
              data-evt-collect="true"
              data-evt-context-dynamic="true"
              data-evt-category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38"
              data-evt-type="18bde62f-bc03-4c32-96fe-bf58df7b81c4"
              attr.data-evt-context-id="{{ customerInfo }}"
              data-evt-element-id="Aceitar oferta pré-fixada à vista"
            >
              {{ 'DEBT.PROPOSAL.ACEPT-PROPOSAL' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="col offer-proposal parceled">
      <div class="content-offer">
        <div>
          <h3 class="text-center">
            {{ 'DEBT.PROPOSAL.PARCELED' | translate }}
          </h3>
          <div class="row">
            <div class="col text-center info-success mb-3 mt-3">
              {{ 'DEBT.PROPOSAL.PRE-APPROVED-PROPOSAL-2' | translate }}
              {{
                installmentNegotiation.arrangementDiscount
                  | currency: 'BRL':'R$':'1.2-2'
              }}
              ({{ installmentNegotiation.discountRate | percent: '2.2' }})
            </div>
          </div>
          <div class="row">
            <div class="col text-center mb-2 mt-2 value">
              {{ 'DEBT.VALUE' | translate }}
            </div>
          </div>
          <div class="row">
            <div class="col text-center mb-2 mt-2">
              <s>{{
                installmentNegotiation.debtTotalBalance
                  | currency: 'BRL':'R$':'1.2-2'
              }}</s>
            </div>
          </div>
          <div class="row">
            <div class="col text-center mb-2 mt-3 value">
              {{ 'DEBT.OFFER-VALUE' | translate }}
            </div>
          </div>
          <div class="row">
            <div class="col text-center mb-3 mt-2 new-value">
              {{
                installmentNegotiation.arrangementAmount
                  | currency: 'BRL':'R$':'1.2-2'
              }}
            </div>
          </div>
          <div class="row">
            <div
              class="col text-center mb-3 mt-3 input-control-ipanema"
              [ngClass]="{
                'has-error': formSubmitted && !endDate && typeSubmitted == 2
              }"
            >
              <div class="label-input">
                <label for="end-date">{{
                  'DEBT.PROPOSAL.END-DATE' | translate
                }}</label>
                <input
                  id="end-date"
                  class="date"
                  placeholder="{{ dateToday | date: 'dd/MM/yyyy' }}"
                  [markDisabled]="markDisabled"
                  name="dp"
                  [(ngModel)]="endDate"
                  ngbDatepicker
                  #dSecond="ngbDatepicker"
                  readonly
                  [placement]="'top'"
                  [minDate]="minDate"
                  [maxDate]="maxDate"
                  (dateSelect)="updateSelectedDate($event)"
                  (click)="parceladoInputGTM()"
                  data-evt-collect="true"
                  data-evt-context-dynamic="true"
                  data-evt-category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38"
                  data-evt-type="4f117429-4a95-4bae-b603-8dd3dc74ccee"
                  attr.data-evt-context-id="{{ customerInfo }}"
                  data-evt-element-id="Data de vencimento input"
                />
              </div>
              <div
                class="input-group-append d-flex"
                (click)="aceitarParceladoBtnGTM(); typeSubmitted = 2"
              >
                <button
                  class="btn btn-outline-secondary btn-sm"
                  (click)="dSecond.toggle()"
                >
                  <em class="fa fa-calendar"></em>
                </button>
              </div>
            </div>
          </div>
          <div class="row">
            <div
              class="
                col
                text-center
                mb-3
                mt-3
                input-control-ipanema
                installments
              "
              [ngClass]="{
                'has-error':
                  formSubmitted && !installmentNegotiation && typeSubmitted == 2
              }"
            >
              <div class="label-input">
                <label for="installment-negotiation">{{
                  'DEBT.PROPOSAL.VALUE-INSTALLMENT-2' | translate
                }}</label>
                <select
                  id="installment-negotiation"
                  [(ngModel)]="installmentNegotiation"
                  (click)="valorParcelaSelectGTM()"
                  data-evt-context-id="{{ customerInfo }}"
                  data-evt-collect="true"
                  data-evt-category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38"
                  data-evt-type="4f117429-4a95-4bae-b603-8dd3dc74ccee"
                  data-evt-element-id="Valor das parcelas select"
                  data-evt-context-dynamic="true"
                >
                  <option
                    [ngValue]="negotiation"
                    *ngFor="let negotiation of negotiations"
                  >
                    {{
                      negotiation.totalInstallments > 1
                        ? (negotiation.downPaymentAmount
                            | currency: 'BRL':'R$':'1.2-2') +
                          ' + ' +
                          (negotiation.totalInstallments - 1) +
                          'x de ' +
                          (negotiation.otherInstallmentsAmount
                            | currency: 'BRL':'R$':'1.2-2')
                        : (negotiation.downPaymentAmount
                          | currency: 'BRL':'R$':'1.2-2')
                    }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col text-center mt-3">
            <button
              class="button primary fechar-acordo {{ debt?.PortfolioName }}-{{
                debt?.PortfolioId
              }}"
              id="{{ datepick }}"
              (click)="accept(installmentNegotiation, endDate, 2)"
              data-evt-collect="true"
              data-evt-context-dynamic="true"
              data-evt-category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38"
              data-evt-type="18bde62f-bc03-4c32-96fe-bf58df7b81c4"
              attr.data-evt-context-id="{{ customerInfo }}"
              data-evt-element-id="Aceitar oferta pré-fixada parcelada"
            >
              <input
                type="hidden"
                data-evt-collect="true"
                data-evt-context-dynamic="true"
                data-evt-category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38"
                data-evt-type="4f117429-4a95-4bae-b603-8dd3dc74ccee"
                attr.data-evt-context-id="{{ customerInfo }}"
                data-evt-element-id="Data de vencimento input"
              />
              {{ 'DEBT.PROPOSAL.ACEPT-PROPOSAL' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Comentado temporariamente -->

    <!--
    <div class="col offer-proposal make-proposal">
      <div class="align-self-center content-offer text-center">
        <div>
          <h3>{{ 'DEBT.PROPOSAL.DONT-LIKE-ANY' | translate }}</h3>
          <div class="row">
            <div class="col text text-center">
              {{ 'DEBT.PROPOSAL.MAKE-YOUR-OFFER' | translate }}
            </div>
          </div>
        </div>
        <div class="row d-flex justify-content-center">
          <img src="assets/images/wallet.png" />
        </div>
        <div class="row pt-4">
          <div class="col text-center">
            <button class="button primary" data-evt-collect="true"
              data-evt-category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38"
              data-evt-type="18bde62f-bc03-4c32-96fe-bf58df7b81c4" attr.data-evt-context-id="{{ customerInfo }}"
              data-evt-element-id="Faça sua proposta" (click)="makeProposal()">
              {{ 'DEBT.PROPOSAL.MAKE-PROPOSTAL' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</div>
