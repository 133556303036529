import { Injectable } from '@angular/core';
import { Debt } from '../shared/models/debt.model';
import { ArrangementService } from './arrangement.service';
import { GoogleAnalyticsService } from './google-analytics.service';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(
    private googleAnalyticsService: GoogleAnalyticsService) { }

  // Envio da Proposta
  sendPurchaseAnalytics(arrangement, category) {
    this.googleAnalyticsService.sendEventWithProps('purchase', {
      currency: 'BRL',
      value: arrangement.ArrangementAmount,
      /**
       * No momento a PIC não retorna o valor principal da dívida, s
       *  será necessário a incluão do campo OriginalDebtPrincipal para o envio ao GA
       */
      // Revenue será utilizado para o envio do valor principal da dívida
      // revenue: arrangement.OriginalDebtPrincipal,
      // ID da transação
      transaction_id: `${arrangement.ArrangementId}`,
      items: [
        {
          //ID da dívida
          id: arrangement.BindingId,
          name: arrangement.PicPortfolioName,
          category: category,
          price: arrangement.ArrangementAmount + arrangement.ArrangementDiscount,
          currency: 'BRL',
          quantity: 1,
          discount: arrangement.ArrangementDiscount
        }
      ]
    });
  }

  // Envio da visualização
  sendBeginCheckoutAnalytics(debt: Debt) {
    this.googleAnalyticsService.sendEventWithProps('begin_checkout', {
      currency: 'BRL',
      items: [{
        // ID da dívida
        id: debt.BindingId,
        name: debt.PicPortfolioName,
        price: debt.DebtTotalBalance,
        currency: 'BRL',
        quantity: 1
      }],
      value: debt.DebtTotalBalance
    });
  }
}
