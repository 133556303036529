export const environment = {
  production: false,
  apiUrl: 'https://api.dev.returncapital.com.br/ipanema/api/v1',
  apiUrlV2: 'https://api.dev.returncapital.com.br/ipanema/api/v2',
  API_KEY: 'oW0i4Gw7On1bKFbKI5XBO36ufA38T8Bo7811ap51',
  SITE_KEY: '6Ldm2nAqAAAAAMtcvJLWMr1BxGvuHzP5GHHny2EH',
  gtmId: 'GTM-MXDCVLP',
  bugsnagApiKey: 'e36e34e8fa6dfe472f3b0eb21126d1a1',
  googleAppId:
    '503607512845-l0l9qm710i1i44h0kc0cd8ff79lae398.apps.googleusercontent.com',
  facebookAppId: '1875812665912223',
  IdeTimeOut: 1800,
  GTMMetricId: 'G-X9J4JETEEP',
  firebaseID: 'G-WW6VEE4D9F',
  universalAnalytics: 'UA-147966704-7',
  DiasLimitePagamento: 5,
  apiApolo: 'https://apollo.dev.returncapital.com.br',
};
