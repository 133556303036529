<div class="modal-body">
  <div class="body-wrapper">
    <div class="header">
      <div class="d-flex justify-content-end">
        <button class="button-close" (click)="close()">
          <em class="fa fa-times"></em>
        </button>
      </div>
      <img loading="lazy" src="assets/images/pix-banner.png" alt="images" rel="preload" />
    </div>
    <div class="body">
      <h4>{{ 'POPUP.PIX.TITLE' | translate }}</h4>
      <p>
        {{ 'POPUP.PIX.DESCRIPTION' | translate }}
      </p>
      <p>
        {{ 'POPUP.PIX.DESCRIPTION-2' | translate }}
      </p>
      <p>
        <em class="fa fa-info-circle"></em> {{ 'POPUP.PIX.INFO' | translate }}
      </p>
      <img class="logo" loading="lazy" src="assets/images/Logo_PIX.png" alt="images" rel="preload" />
      <button class="button primary" data-evt-collect="true" data-evt-category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38"
        data-evt-type="18bde62f-bc03-4c32-96fe-bf58df7b81c4" data-evt-element-id="Negocie aqui"
        attr.data-evt-context-id="{{ customerInfo }}" (click)="goTo()">
        {{ 'POPUP.PIX.BUTTON' | translate }}
      </button>
    </div>
  </div>
</div>