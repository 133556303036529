<div class="modal-wrapper">
  <app-loading [show]="loading"></app-loading>
  <div class="modal-header">
    <em type="button" class="close" aria-label="Close" (click)="close()" tabindex="0">
      <img src="./assets/images/icon-close.png" alt="icone fechar modal" rel="preload" />
    </em>
  </div>
  <div class="modal-body">
    <h1>{{ header | translate }}</h1>
    <div class="col">
      <div *ngIf="!confirm">
        <form class="login-form align-self-stretch" [formGroup]="profileForm">
          <div class="d-flex flex-column m-5">
            <app-box-input *ngFor="let i of formInputs" [id]="i.id" [type]="i.type" [visible]="i.visible"
              label="{{ i.label | translate }}" placeholder="{{ i.placeholder | translate }}"
              [controlName]="i.controlName" [value]="value" (valueChanged)="getInputValue($event)"></app-box-input>
          </div>
          <!-- Confirmação de senha para outros campos-->
          <div class="d-flex flex-column m-5">
            <div class="box-input-wrapper">
              <div class="box-input-container" *ngIf="control != 'password'">
                <label for="confirmPassowrd">{{'PROFILE.MODAL.INPUTS.CONFIRMPASSWORD.LABEL' | translate}}</label>
                <input [type]="password" id="confirmPassowrd" name="confirmPassowrd"
                  placeholder="{{'PROFILE.MODAL.INPUTS.CONFIRMPASSWORD.PLACEHOLDER' | translate}}" required>
                <em class="fa fa-eye-slash btn-toggle-password" (click)="togglePasswordVisibility()" *ngIf="!show"
                  (keypress)="togglePasswordVisibility()"></em>
                <em class="fa fa-eye btn-toggle-password" (click)="togglePasswordVisibility()" *ngIf="show"
                  (keypress)="togglePasswordVisibility()"></em>
              </div>
              <!-- Confirmação de senha para troca de senha -->
              <div class="box-input-container" *ngIf="control == 'password'">
                <label for="confirmNewPassowrd">{{'PROFILE.MODAL.INPUTS.NEWPASSOWRD.LABEL' | translate}}</label>
                <input [type]="password" id="confirmNewPassowrd" name="confirmNewPassowrd"
                  placeholder="{{'PROFILE.MODAL.INPUTS.NEWPASSOWRD.PLACEHOLDER' | translate}}" required>
                <em class="fa fa-eye-slash btn-toggle-password" (click)="togglePasswordVisibility()" *ngIf="!show"
                  (keypress)="togglePasswordVisibility()"></em>
                <em class="fa fa-eye btn-toggle-password" (click)="togglePasswordVisibility()" *ngIf="show"
                  (keypress)="togglePasswordVisibility()"></em>
              </div>

            </div>

          </div>
        </form>
      </div>

      <div *ngIf="confirm">
        <form class="login-form align-confirm align-self-stretch" [formGroup]="profileConfirm">
          <app-box-input *ngFor="let i of confirmInputs" [id]="i.id" [type]="i.type" label="{{ i.label | translate }}"
            placeholder="{{ i.placeholder | translate }}" [visible]="i.visible" [controlName]="i.controlName"
            [value]="value" (valueChanged)="getInputConfirm($event)"></app-box-input>
        </form>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="button outline mr-3" (click)="close()" (keypress)="close()">
      {{ 'PROFILE.MODAL.BUTTONS.CLOSE' | translate }}
    </button>
    <button class="button primary btn-login" type="button" id="btn_update_confirmation" (click)="onSubmit()"
      [disabled]="!profileForm.valid">
      {{ 'PROFILE.MODAL.BUTTONS.SAVE' | translate }}
    </button>
  </div>
</div>