<app-loading [show]="loading"></app-loading>
<form [formGroup]="proposalForm">
  <div class="make-your-proposal">
    <div class="row">
      <div class="col">
        <h4>{{ 'DEBT.PROPOSAL.MAKE-PROPOSTAL' | translate }}</h4>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-10 col-xl-10 col-12">
        <p>{{ 'DEBT.PROPOSAL.MAKE-PROPOSAL-TEXT' | translate }}</p>
      </div>
    </div>
    <div class="row value mb-0">
      <h4 class="col">{{ 'DEBT.VALUE' | translate }}</h4>
      <div class="col text-right currency">
        {{ debt.DebtTotalBalance | currency: 'BRL':'R$':'1.2-2' }}
      </div>
    </div>
    <div class="row value">
      <h4 class="col">{{ 'DEBT.VALUE-CUSTOM-OFFER' | translate }}</h4>
      <div class="col text-right currency">
        {{ (sumBalance) | currency: 'BRL':'R$':'1.2-2' }}
      </div>
    </div>
    <div class="row controls">
      <div class="col input-control-ipanema"
        [ngClass]="{ 'has-error': formSubmitted && !proposalForm?.get('DownPaymentAmount').value }">
        <div class="label-input text-left">
          <label for="DownPaymentAmount">{{
            'DEBT.PROPOSAL.ENTER-VALUE' | translate
            }}</label>
          <input class="text-left" placeholder="{{
            'DEBT.PROPOSAL.ENTER-VALUE-PLACEHOLDER' | translate
          }}" data-evt-collect="true" data-evt-category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38"
            data-evt-type="4f117429-4a95-4bae-b603-8dd3dc74ccee" attr.data-evt-context-id="{{ customerInfo }}"
            data-evt-element-id="Valor da entrada input" data-evt-context-dynamic="true"
            formControlName="DownPaymentAmount" id="DownPaymentAmount" prefix="R$ " mask="separator.2"
            thousandSeparator="." type="text" [dropSpecialCharacters]="false" (focusout)="validateDownPaymentAmount()"
            (click)="entradaInputGTM()" />
        </div>
      </div>
      <div class="col input-control-ipanema"
        [ngClass]="{ 'has-error': formSubmitted && !proposalForm?.get('InstallmentAmount').value }">
        <div class="label-input text-left">
          <label for="InstallmentAmount">{{
            'DEBT.PROPOSAL.MONTH-VALUE' | translate
            }}</label>
          <input class="text-left" placeholder="{{
            'DEBT.PROPOSAL.MONTH-VALUE-PLACEHOLDER' | translate
          }}" data-evt-collect="true" data-evt-category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38"
            data-evt-type="4f117429-4a95-4bae-b603-8dd3dc74ccee" attr.data-evt-context-id="{{ customerInfo }}"
            data-evt-element-id="Valor a pagar por mês input" data-evt-context-dynamic="true"
            formControlName="InstallmentAmount" id="InstallmentAmount" prefix="R$ " mask="separator.2"
            thousandSeparator="." type="text" [dropSpecialCharacters]="false" (focusout)="validateInstallmentAmount()"
            (click)="valorMensalInputGTM()" />
        </div>
      </div>
    </div>
    <div class="row controls">
      <div class="col mt-3 input-control-ipanema"
        [ngClass]="{ 'has-error': formSubmitted && !proposalForm?.get('TotalInstallments').value }">
        <div class="label-input text-left">
          <label for="TotalInstallments">{{
            'DEBT.PROPOSAL.INSTALMMENTS' | translate
            }}</label>
          <select class="text-left" formControlName="TotalInstallments" data-evt-collect="true"
            data-evt-category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38"
            data-evt-type="4f117429-4a95-4bae-b603-8dd3dc74ccee" attr.data-evt-context-id="{{ customerInfo }}"
            data-evt-element-id="Parcelas input" data-evt-context-dynamic="true" id="TotalInstallments"
            (click)="parcelasSelectGTM()">
            <option [value]="installment" *ngFor="let installment of installments">
              {{ installment }}
            </option>
          </select>
        </div>
      </div>
      <div class="col mt-3 text-center input-control-ipanema"
        [ngClass]="{ 'has-error': formSubmitted && !proposalForm?.get('FirstInstallmentDate').value }">
        <div class="label-input text-left">
          <label for="FirstInstallmentDate">{{
            'DEBT.PROPOSAL.END-DATE' | translate
            }}</label>
          <input class="text-left" #datapick id="FirstInstallmentDate" placeholder="dd/mm/aaaa"
            [markDisabled]="markDisabled" name="dp" formControlName="FirstInstallmentDate" ngbDatepicker
            #dSecond="ngbDatepicker" [placement]="'top'" [minDate]="minDate" [maxDate]="maxDate" readonly
            (click)="dataVencimentoInputGTM()" data-evt-collect="true"
            data-evt-category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38"
            data-evt-type="4f117429-4a95-4bae-b603-8dd3dc74ccee" attr.data-evt-context-id="{{ customerInfo }}"
            data-evt-element-id="Data de vencimento input" data-evt-context-dynamic="true" />
        </div>
        <div class="input-group-append d-flex">
          <button class="btn btn-outline-secondary btn-sm" (click)="dSecond.toggle()" type="button">
            <i class="fa fa-calendar"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col col-lg-9 col-md-8 mt-3 p-0 text-right">
        <button class="button outline" data-evt-collect="true" data-evt-category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38"
          data-evt-type="18bde62f-bc03-4c32-96fe-bf58df7b81c4" attr.data-evt-context-id="{{ customerInfo }}"
          data-evt-element-id="Cancelar" (click)="hideMakeProposal()">
          {{ 'BUTTONS.CANCEL' | translate }}
        </button>
      </div>
      <div class="col col-lg-3 col-md-4 mt-3 p-0 text-right">
        <button id="{{ datapick.value }}" class="button primary" data-evt-collect="true" data-evt-context-dynamic="true"
          data-evt-category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38" data-evt-type="18bde62f-bc03-4c32-96fe-bf58df7b81c4"
          attr.data-evt-context-id="{{ customerInfo }}" data-evt-element-id="Enviar Proposta" (click)="send()">
          {{ 'BUTTONS.SEND-PROPOSAL' | translate }}
        </button>
      </div>
    </div>
  </div>
</form>