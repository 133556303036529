import { Injectable } from '@angular/core';
declare const fbq;

@Injectable({
  providedIn: 'root'
})
export class MetaPixelService {

  constructor() { }

  public trackEvent(eventName: string) {

    fbq('track', eventName)
  }

  public trackPaymentInfo() {
    this.trackEvent('AddPaymentInfo')
  }
  public trackCart() {
    this.trackEvent('AddToCart')
  }
  public trackRegistration() {
    this.trackEvent('CompleteRegistration')
  }
  public trackContact() {
    this.trackEvent('Contact')
  }
  public trackCheckout() {
    this.trackEvent('IntiateCheckout')
  }
  public trackLead() {
    this.trackEvent('Lead')
  }
}
