<swiper [config]="config">
  <div class="swiper-wrapper">
    <div class="swiper-slide">
      <img
        loading="lazy"
        class="corp-image-mobile"
        src="assets/images/santander.svg"
        alt="santander"
        rel="preload"
      />
    </div>
    <div class="swiper-slide">
      <img
        loading="lazy"
        class="corp-image-mobile"
        src="assets/images/calcard.svg"
        alt="calcard"
        rel="preload"
      />
    </div>
    <div class="swiper-slide">
      <img
        loading="lazy"
        class="corp-image-mobile"
        src="assets/images/getnet.svg"
        alt="getnet"
        rel="preload"
      />
    </div>
    <div class="swiper-slide">
      <img
        loading="lazy"
        class="corp-image-mobile"
        src="assets/images/marisa.svg"
        alt="marisa"
        rel="preload"
      />
    </div>
    <div class="swiper-slide">
      <img
        loading="lazy"
        class="corp-image-mobile"
        src="assets/images/banco-pan.svg"
        alt="banco-pan"
        rel="preload"
      />
    </div>
  </div>
  <div class="swiper-button-prev nav_brands" id="corporation-prev"></div>
  <div class="swiper-button-next nav_brands" id="corporation-next"></div>
</swiper>
