import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  ViewChild,
  HostListener,
} from '@angular/core';
import { DebtService } from '../../services/debt.service';
import { ArrangementService } from '../../services/arrangement.service';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  NavigationStart,
  RouterStateSnapshot,
} from '@angular/router';
import { Debt } from '../../shared/models/debt.model';
import { Arrangement } from '../../shared/models/arrangement.model';
import { Negotiation } from '../../shared/models/negotiation.model';
import { ToastService } from '../../services/toast.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { Router, CanDeactivate } from '@angular/router';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { ApoloService } from 'src/app/services/apolo.service';
import { AuthenticationService } from '../../services/authentication.service';
import { PlatformLocation } from '@angular/common';
import { PaymentMethod } from 'src/app/shared/models/payment-method.constant';
import { AuthGuard } from 'src/app/helpers/auth.guard';
import { DebtProposalComponent } from 'src/app/shared/components/debt-proposal/debt-proposal.component';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MetaPixelService } from 'src/app/services/meta-pixel.service';
let gtag: Function;

@Component({
  selector: 'app-debt',
  templateUrl: './debt.component.html',
  styleUrls: ['./debt.component.scss'],
})
export class DebtComponent implements OnInit {
  // @HostListener('window:unload', ['$event'])
  // unloadHandler(event: any) {
  //   console.log('hit');
  // }

  @ViewChild(DebtProposalComponent) debtProposal: any;
  debt: Debt;
  arrangement: Arrangement;
  negotiation: Negotiation;
  loading = false;
  dataMock = 'xxxxxxxxxxxxxxxxx';
  showMakeProposal = false;
  isArrangement = false;
  delayDebt: string;
  customerInfo: any = {};
  coupon: FormGroup;
  couponResult;
  negotiations: any;
  installmentNegotiation: any;
  negotiationDate: Date;
  isValid: boolean;
  methods = [
    { label: 'Boleto Bancário', value: 3 },
    { label: 'PIX', value: 11 },
  ];
  paymentMethod: PaymentMethod = 0;
  isSubmitted: boolean = false;
  //Only for arrangement updates
  installmentNumber: number;

  public isCollapsed = true;

  // canExit() {
  //   if (confirm('Deseja sair da tela? Alterações não serão salvas.')) {
  //     return true;
  //   } else {
  //     history.pushState('', '', '/painel');
  //   }
  // }

  constructor(
    public authenticationService: AuthenticationService,
    private debtService: DebtService,
    private arrangementService: ArrangementService,
    private route: ActivatedRoute,
    private toastService: ToastService,
    private router: Router,
    private translate: TranslateService,
    private analyticsService: AnalyticsService,
    private gtmService: GoogleTagManagerService,
    private apoloService: ApoloService,
    private location: PlatformLocation,
    private formBuilder: FormBuilder,
    private metaService: MetaPixelService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    throw new Error('Method not implemented.');
  }

  ngOnInit() {
    this.couponResult = null;
    this.isValid = false;

    document.all[0].setAttribute(
      'data-evt-page',
      'c4ce2a74-f90a-4e1f-addb-8a8fce648f50'
    );

    this.apoloService.sendData();

    this.authenticationService.getCustomerInfo().then((rtn) => {
      this.customerInfo = rtn;
    });

    const id = this.route.snapshot.paramMap.get('id');

    if (this.router.url.search(/acordo/gi) > 0) {
      this.isArrangement = true;
    }

    if (this.isArrangement) {
      this.getArrangement(id);
    } else {
      this.getDebt(id);
    }

    this.sendProductInteraction();
    this.selectItem();
    this.sendDataPurchase();
    this.beginCheckout();
    this.sendImpressions();
    this.productClick();
    this.UApurchase();
    this.UAcheckout();
    this.createCouponForm();
  }

  createCouponForm() {
    this.coupon = this.formBuilder.group({
      CouponCode: null,
    });
  }

  getArrangement(id): any {
    this.loading = true;
    this.arrangement = null;
    this.arrangementService.getById(id).subscribe(
      (ret: any) => {
        this.arrangement = ret;
        this.getDebt(this.arrangement.DebtId);
      },
      (err) => {
        this.loading = false;
      }
    );
  }

  return() {
    window.onunload = function () {
      return false;
    };
  }

  validateCoupon() {
    this.debtService
      .validateDebtCoupon(this.debt.BindingId, this.coupon.value.CouponCode)
      .subscribe(
        (res) => {
          if (res.data != null) {
            this.isValid = true;
            this.couponResult = res.data.couponId;
            this.toastService.show({
              body: 'Cupom aplicado com sucesso!',
              class: 'bg-success',
            });
            document.getElementById('cupom').style.borderColor = '#ccf1e7';
            this.debtProposal.getNegotiations(this.debtProposal.datepick);
          } else if (res.data == null) {
            this.toastService.show({
              body: 'Cupom inválido.',
              class: 'bg-danger',
            });
            // this.coupon.controls['CouponCode'].reset();
            document.getElementById('cupom').style.borderColor = '#ff4f4f';
          }
        },
        (error) => {
          this.loading = false;
        }
      );
  }
  clearCoupon() {
    this.negotiationDate =
      this.debtProposal.datepick == undefined

        ? this.coupon.value.ReferenceDate
        : moment(this.debtProposal.datepick).toDate();
    this.coupon.reset();
    this.couponResult = null;
    this.debtProposal.getNegotiations(this.debtProposal.datepick);
    this.isValid = false;
    document.getElementById('cupom').style.borderColor = '#B2BAC4';
  }

  updateArrangement(installment: number) {
    const id = this.route.snapshot.paramMap.get('id');
    this.getArrangement(id);
    this.installmentNumber = installment;
  }

  changePayMethod(event) {

    this.apoloService.sendManualEvent(
      'f3682a46-8e88-486a-ae4a-1b3ef41f4d38',
      '5e48115d-6ada-4ff5-a140-09b9e963b0b1',
      'SELECT',
      this.paymentMethod == 11 ? 'PIX' : 'Boleto Bancário',
      'mouseup',
      this.customerInfo,
      this.paymentMethod
    );
    this.metaService.trackPaymentInfo();
  }

  getDebt(id): any {
    this.loading = true;
    this.debtService.getById(id).subscribe(
      (ret: any) => {
        this.debt = ret;
        this.getOpenDays();
        this.analyticsService.sendBeginCheckoutAnalytics(this.debt);
        this.loading = false;
      },
      (err) => {
        this.loading = false;
      }
    );
  }

  getRightSideTitle(): string {
    if (
      (this.debt && this.debt.DebtStatus == 'ACTIVE') ||
      (this.arrangement && this.debt.DebtStatus == 'PROMISE')
    ) {
      if (this.debt.HasLegalClaim) {
        return 'DEBT.WARNING-TITLE';
      }
      return 'DEBT.PROPOSAL-TITLE';
    }
    return 'DEBT.ARRANGEMENT-TITLE';
  }

  getOpenDays() {
    let today = moment();
    let day = moment(this.debt.FirstDefaultDate);
    if (today.diff(day, 'days') <= 30) {
      this.concatLabel(
        today.diff(day, 'days'),
        today.diff(day, 'days') > 1 ? 'DAYS' : 'DAY'
      );
    } else if (today.diff(day, 'months') <= 12) {
      this.concatLabel(
        today.diff(day, 'months'),
        today.diff(day, 'months') > 1 ? 'MONTHS' : 'MONTH'
      );
    } else {
      this.concatLabel(
        today.diff(day, 'years'),
        today.diff(day, 'years') > 1 ? 'YEARS' : 'YEAR'
      );
    }
  }

  concatLabel(value, description) {
    if (isNaN(value)) {
      this.delayDebt = '';
    } else {
      this.translate
        .get(`DEBT.LAG-TIME-LABEL.${description}`)
        .subscribe((res: string) => {
          this.delayDebt = `${value} ${res}`;
        });
    }
  }

  MakeProposal(event) {
    this.showMakeProposal = event;
  }

  setSubmitted(event) {
    this.isSubmitted = event;
  }

  showContent(content) {
    let rtn = false;
    switch (content) {
      case 'DEBT_PROPOSAL':
        // Opção dos 3
        if (this.debt.DebtStatus == 'ACTIVE' && !this.showMakeProposal) {
          rtn = true;
        }
        break;

      case 'MAKE_PROPOSAL':
        // Faça sua própria
        if (this.debt.DebtStatus == 'ACTIVE' && this.showMakeProposal) {
          rtn = true;
        }
        break;
      case 'ARRANGEMENT_IN_DEBT':
        //Acordo análise
        if (
          this.arrangement &&
          this.arrangement.ArrangementStatus == 'REVIEW' &&
          !this.showMakeProposal
        ) {
          rtn = true;
        }
        break;

      case 'DEBT_AGREEMENT':
        //Acordo promessa
        const status = [
          'PROMISE',
          'PAY_OFF',
          'AGREEMENT',
          'AGREEMENT_LEGACY',
          'EXCEPTION_ACCEPTED',
        ];

        if (this.arrangement) {
          if (
            status.includes(this.debt.DebtStatus) &&
            !this.showMakeProposal &&
            this.arrangement.ArrangementStatus != 'REVIEW'
          ) {
            rtn = true;
          }
        } else if (
          status.includes(this.debt.DebtStatus) &&
          !this.showMakeProposal
        ) {
          rtn = true;
        }
        break;

      default:
        rtn = false;
        break;
    }

    return rtn;
  }

  // Ecommerce
  public sendProductInteraction() {
    const interaction = {
      event: 'view_item',
      ecommerce: {
        items: [
          {
            item_name: this.arrangement?.PortfolioName,
            item_id: this.arrangement?.ArrangementId,
            item_category: this.arrangement?.ArrangementDescription,
            item_brand: this.arrangement?.PicPortfolioName,
            quantity: this.arrangement?.TotalInstallments,
          },
        ],
      },
    };
    this.gtmService.pushTag(interaction);
  }

  // GA4_select_item
  public selectItem() {
    const item = {
      event: 'select_item',
      ecommerce: {
        items: [
          {
            item_name: this.arrangement?.PortfolioName,
            item_id: this.arrangement?.ArrangementId,
            item_category: this.arrangement?.ArrangementDescription,
            item_brand: this.arrangement?.PicPortfolioName,
            quantity: this.arrangement?.TotalInstallments,
          },
        ],
      },
    };
    this.gtmService.pushTag(item);
  }

  // GA4_purchase
  public sendDataPurchase() {
    const purchase = {
      event: 'purchase',
      ecommerce: {
        transaction_id: this.arrangement?.ArrangementId,
        value: this.arrangement?.ArrangementBalance,
        currency: 'BRL',
        items: [
          {
            item_name: this.arrangement?.PortfolioName,
            item_id: this.arrangement?.ArrangementId,
            item_category: this.arrangement?.ArrangementDescription,
            item_brand: this.arrangement?.PicPortfolioName,
            price: this.arrangement?.ArrangementAmount,
            quantity: this.arrangement?.TotalInstallments,
          },
        ],
      },
    };
    this.gtmService.pushTag(purchase);
  }

  // GA4_begin_checkout
  public beginCheckout() {
    const purchase = {
      event: 'begin_checkout',
      ecommerce: {
        items: [
          {
            item_name: this.arrangement?.PortfolioName,
            item_id: this.arrangement?.ArrangementId,
            item_brand: this.arrangement?.PicPortfolioName,
            item_category: this.arrangement?.ArrangementDescription,
            quantity: this.arrangement?.TotalInstallments,
          },
        ],
      },
    };
    this.gtmService.pushTag(purchase);
  }

  // ua_impressions
  public sendImpressions() {
    const impressions = {
      event: 'impressions',
      ecommerce: {
        impressions: [
          {
            name: this.arrangement?.PortfolioName,
            id: this.arrangement?.ArrangementId,
            category: this.arrangement?.ArrangementDescription,
            quantity: this.arrangement?.TotalInstallments,
          },
        ],
      },
    };
    this.gtmService.pushTag(impressions);
  }

  // ua_productClick
  public productClick() {
    const product = {
      event: 'productClick',
      ecommerce: {
        click: [
          {
            product: [
              {
                name: this.arrangement?.PortfolioName,
                id: this.arrangement?.ArrangementId,
                brand: this.arrangement?.PortfolioName,
                category: this.arrangement?.ArrangementDescription,
                quantity: this.arrangement?.TotalInstallments,
              },
            ],
          },
        ],
      },
    };
    this.gtmService.pushTag(product);
  }

  // ua_purchase
  public UApurchase() {
    const purchase = {
      event: 'ua_purchase',
      ecommerce: {
        currency: 'BRL',
        purchase: {
          actionField: {
            id: this.arrangement?.ArrangementId,
            revenue: this.arrangement?.ArrangementDiscount,
          },
          products: [
            {
              name: this.arrangement?.PortfolioName,
              id: this.arrangement?.ArrangementId,
              price: this.arrangement?.ArrangementAmount,
              brand: this.arrangement?.PortfolioName,
              category: this.arrangement?.ArrangementDescription,
              quantity: this.arrangement?.TotalInstallments,
            },
          ],
        },
      },
    };
    this.gtmService.pushTag(purchase);
  }

  // ua_checkout
  public UAcheckout() {
    const purchase = {
      event: 'ua_checkout',
      ecommerce: {
        currency: 'BRL',
        checkout: {
          actionField: {
            step: '1',
            option: this.arrangement?.DefaultPaymentMethodAlias,
          },
          products: [
            {
              name: this.arrangement?.PortfolioName,
              id: this.arrangement?.ArrangementId,
              price: this.arrangement?.ArrangementDiscount,
              brand: this.arrangement?.PortfolioName,
              category: this.arrangement?.ArrangementDescription,
              quantity: this.arrangement?.TotalInstallments,
            },
          ],
        },
      },
    };
    this.gtmService.pushTag(purchase);
  }
}
