import { Component, OnInit } from '@angular/core';
import {SwiperOptions} from 'swiper';

@Component({
  selector: 'app-opinion-section',
  templateUrl: './opinion-section.component.html',
  styleUrls: ['./opinion-section.component.scss']
})
export class OpinionSectionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
