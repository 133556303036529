<div class="modal-body">
  <div class="row">
    <div class="col-12 text-center" id="1" *ngIf="item == 1">
      <div class="img">
        <img
          src="../assets/images/01.png"
          alt="Ipanema Onboarding 1"
          rel="preload"
        />
      </div>
      <h5>{{ 'ONBOARDING.TITLE-1' | translate }}</h5>
      <p>{{ 'ONBOARDING.SUBTITLE-1' | translate }}</p>
    </div>
    <div class="col-12 text-center" id="2" *ngIf="item == 2">
      <div class="img">
        <img
          src="../assets/images/02.png"
          alt="Ipanema Onboarding 2"
          rel="preload"
        />
      </div>
      <h5>{{ 'ONBOARDING.TITLE-2' | translate }}</h5>
      <p>{{ 'ONBOARDING.SUBTITLE-2' | translate }}</p>
    </div>
    <div class="col-12 text-center" id="3" *ngIf="item == 3">
      <div class="img">
        <img
          src="../assets/images/03.png"
          alt="Ipanema Onboarding 3"
          rel="preload"
        />
      </div>
      <h5>{{ 'ONBOARDING.TITLE-3' | translate }}</h5>
      <p>{{ 'ONBOARDING.SUBTITLE-3' | translate }}</p>
    </div>
    <div class="col-12 text-center" id="4" *ngIf="item == 4">
      <div class="img">
        <img
          src="../assets/images/04.png"
          alt="Ipanema Onboarding 4"
          rel="preload"
        />
      </div>
      <h5>{{ 'ONBOARDING.TITLE-4' | translate }}</h5>
      <p>{{ 'ONBOARDING.SUBTITLE-4' | translate }}</p>
    </div>
  </div>
  <div class="row">
    <div class="col-12 text-right">
      <button class="button primary" (click)="goToNext()" *ngIf="item !== 4">
        {{ 'ONBOARDING.NEXT' | translate }}
      </button>
      <button class="button primary" (click)="close()" *ngIf="item == 4">
        {{ 'ONBOARDING.START' | translate }}
      </button>
    </div>
  </div>
</div>
