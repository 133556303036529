import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbTooltip, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss']
})
export class TimerComponent implements OnInit, AfterViewInit {

  /** Total time of timer */
  @Input() time: number = 180000;

  /** Time to notify parent component (Action) */
  @Input() notifyEachTime: number = 10000;

  /** current time */
  currentTime: number;

  /** Time to load interval and make changes to timer (time and bar) */
  miliseconds: number = 50;

  /** Identifiers of interval */
  interval;

  /** Percent to load bar smoothly */
  percentTime: number;

  /** Event Emitter to notify parent on final timer and each defined seconds ('notifyEachTime') respectively */
  @Output() notify: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() notifyEach: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('tooltip') public tooltip: NgbTooltip;

  constructor() { }

  ngOnInit(): void {
    this.currentTime = this.time;
    this.interval = setInterval(() => this.countSeconds(), this.miliseconds);
  }

  ngAfterViewInit(): void {
    this.tooltip.open();
  }

  countSeconds() {
    if (this.currentTime > 0) {
      this.currentTime -= this.miliseconds;
      this.percentTime = (this.currentTime * 100) / this.time;

      // Notify interval
      if (this.currentTime % this.notifyEachTime == 0) {
        this.notifyEach.emit();
      }
    } else {
      clearInterval(this.interval);
      this.notify.emit(false);
    }
  }

}
