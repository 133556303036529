import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-status-debt',
  templateUrl: './status-debt.component.html',
  styleUrls: ['./status-debt.component.scss']
})
export class StatusDebtComponent implements OnInit {
  @Input() state: any;
  @Output() stateValue = new EventEmitter();

  public debtStatus = {
    cssClass: '',
    value: '',
  };

  constructor() { }

  ngOnInit(): void {
    this.parseDebtStatus(this.state);
  }

  parseDebtStatus(status: string): void {
    switch (status) {
      case 'ACTIVE':
        this.debtStatus.cssClass = 'active';
        this.debtStatus.value = 'DÍVIDA';
        break;

      case 'PAY_OFF':
      case 'EXCEPTION_PAY_OFF':
        this.debtStatus.cssClass = 'pay-off';
        this.debtStatus.value = 'QUITADA';
        break;

      case 'PROMISE':
      case 'EXCEPTION_ACCEPTED':
      case 'AGREEMENT':
      case 'AGREEMENT_LEGACY':
        this.debtStatus.cssClass = 'in-progress';
        this.debtStatus.value = 'EM ANDAMENTO';
        break;

      case 'REVIEW':
      case 'WAITING':
        this.debtStatus.cssClass = 'promise';
        this.debtStatus.value = 'EM ANÁLISE';
        break;
    }
    this.stateValue.emit(this.debtStatus.value);
  }

}
