import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Arrangement } from '../../models/arrangement.model';
import { DebtService } from '../../../services/debt.service';
import { Discharge } from '../../models/discharge.model';
import { ArrangementService } from 'src/app/services/arrangement.service';
import { ToastService } from 'src/app/services/toast.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DischargeModalComponent } from '../discharge-modal/discharge-modal.component';
import { GoogleAnalyticsService } from '../../../services/google-analytics.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { AuthenticationService } from '../../../services/authentication.service';
import { ConfirmPaymentModalComponent } from '../confirm-payment-modal/confirm-payment-modal.component';
import { Installment } from '../../models/installment.model';
import {
  PaymentConfirmation,
  PaymentStatusTypes,
} from '../../models/paymentConfirmation.model';

@Component({
  selector: 'app-debt-agreement',
  templateUrl: './debt-agreement.component.html',
  styleUrls: ['./debt-agreement.component.scss'],
})
export class DebtAgreementComponent implements OnInit, OnChanges {
  @Input() public arrangement: Arrangement;
  @Input() public installmentNumber: number;
  public correctInvoiceTitle: string;
  public installment: Installment;
  public installmentSelected: number;
  public coppied = false;
  public isLoading = false;
  customerInfo: any = {};
  isQRCodeVisible = false;
  installmentQRCodeVisible = 0;
  modalPaymentOpened: boolean = false;

  discharge: Discharge;
  @Output() public updateArrangement: EventEmitter<number> =
    new EventEmitter<number>();

  constructor(
    public authenticationService: AuthenticationService,
    private arrangementService: ArrangementService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private modalService: NgbModal,
    private gtmService: GoogleTagManagerService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.getCorrectInvoice(this.arrangement.ArrangementInstallmentResponseList);
  }

  ngOnInit(): void {
    this.authenticationService.getCustomerInfo().then((rtn) => {
      this.customerInfo = rtn;
    });

    this.getCorrectInvoice(this.arrangement.ArrangementInstallmentResponseList);
  }

  hideQRCode() {
    this.isQRCodeVisible = false;
  }

  showQRCode() {
    if (!this.installment.QRCode) {
      this.isLoading = true;
      this.arrangementService
        .generatePix(this.arrangement.ArrangementId, {
          InstallmentID: this.installment.InstallmentID,
        })
        .toPromise()
        .then((res: any) => {
          this.installment.BilletLine = res.BilletLine;
          this.installment.QRCode = res.QrCode;
          this.isQRCodeVisible = true;
          this.installmentQRCodeVisible = this.installment.InstallmentID;
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    } else {
      this.isQRCodeVisible = true;
    }
  }

  checkPayment() {
    if (!this.modalPaymentOpened) {
      this.arrangementService
        .checkPaymentPix(this.installment.InstallmentID)
        .toPromise()
        .then((res: PaymentConfirmation) => {
          if (
            res.installmentID &&
            (res.status == PaymentStatusTypes.PAID ||
              res.status == PaymentStatusTypes.PARCIAL_PAYD)
          ) {
            this.showModalConfirmationPixResponse(res.status);
          }
        });
    }
  }

  showModalConfirmationPixResponse(status: number) {
    let modalRefConfirm = this.modalService.open(ConfirmPaymentModalComponent, {
      centered: true,
      backdrop: 'static',
      keyboard: false,
    });

    this.modalPaymentOpened = true;
    modalRefConfirm.componentInstance.status = status;

    modalRefConfirm.result.then((ret) => {
      modalRefConfirm.close();
      this.modalPaymentOpened = false;
      this.isQRCodeVisible = false;

      if (this.arrangement.InstallmentAmount > 1) {
        this.updateArrangement.emit(this.installmentSelected);
      } else {
        window.location.reload();
      }
    });
  }

  getExpirationDay(date) {
    const dateFormated = new Date(date);
    return dateFormated;
  }

  getMonthNameAndYear(date) {
    const dateFormated = new Date(date);
    return (
      dateFormated.toLocaleString('pt-BR', { month: 'long' }) +
      ' de ' +
      dateFormated.getFullYear()
    );
  }

  getCpfCnpjFormatted(cpfCnpj) {
    if (!cpfCnpj && cpfCnpj.length < 10) {
      return cpfCnpj;
    }
    if (cpfCnpj.length < 12) {
      return `${cpfCnpj.substr(0, 3)}.${cpfCnpj.substr(3, 3)}.${cpfCnpj.substr(
        6,
        3
      )}-${cpfCnpj.substr(-1)}`;
    } else {
      return `${cpfCnpj.substr(0, 2)}.${cpfCnpj.substr(2, 3)}.${cpfCnpj.substr(
        5,
        3
      )}/${cpfCnpj.substr(8, 4)}-${cpfCnpj.substr(12)}`;
    }
  }

  async getCorrectInvoice(installmentsList: Installment[]) {
    // Mudança para retornar a ultima fatura em aberto e nao a do mes atual

    let installmentMonths = installmentsList.filter((installment) => {
      return installment.SettlementDate == null;
    });

    if (this.installmentNumber) {
      let inst = installmentsList.filter(
        (i) => i.InstallmentNumber == this.installmentNumber
      );
      if (inst && inst.length) {
        this.installment = inst[0];
      }
    } else {
      this.installment = installmentMonths.length
        ? installmentMonths.shift()
        : installmentsList[0];
    }

    this.installmentSelected = this.installment.InstallmentNumber;
    let datTemp = new Date(this.installment?.RemitDueDate);

    if (this.installment?.RemitDueDate) {
      this.correctInvoiceTitle =
        'Fatura ' +
        datTemp.toLocaleString('pt-BR', { month: 'long' }) +
        ' de ' +
        datTemp.getFullYear();
    } else {
      this.correctInvoiceTitle = 'Selecione';
    }
  }

  setInstallment(installment) {
    this.installment = installment;

    if (this.installmentQRCodeVisible == installment.InstallmentID) {
      this.isQRCodeVisible = true;
    } else {
      this.isQRCodeVisible = false;
    }

    let datTemp = new Date(installment.RemitDueDate);

    this.correctInvoiceTitle =
      'Fatura ' +
      datTemp.toLocaleString('pt-BR', { month: 'long' }) +
      ' de ' +
      datTemp.getFullYear();
  }

  copyToClipboard(billetLine) {
    this.googleAnalyticsService.sendClickEvent('payment', 'btn_copy_barcode');
    var textArea = document.createElement('textarea');
    document.body.appendChild(textArea);
    textArea.value = billetLine;

    navigator.clipboard.writeText(textArea.value).then(() => {
      document.body.removeChild(textArea);

      this.coppied = true;

      setTimeout(() => {
        this.coppied = false;
      }, 3000);
    });
  }

  generateLastBill() {
    this.isLoading = true;
    this.arrangementService
      .generateBill(this.arrangement.ArrangementId, {
        PortfolioType: 'PURCHASED_WALLETS',
      })
      .toPromise()
      .then((res: any) => {
        this.googleAnalyticsService.sendClickEvent(
          'payment',
          'btn_view_bankslip'
        );

        let today = new Date();
        let downloadURL = window.URL.createObjectURL(res);
        let link = document.createElement('a');
        link.href = downloadURL;
        link.download = `${
          this.arrangement.ArrangementId
        }-${today.toLocaleDateString()}.pdf`;
        link.click();
        this.isLoading = false;
      })
      .catch(() => {
        this.isLoading = false;
      });

    const gtmTag = {
      event: 'eventGA',
      category: 'portal:acordo',
      action: 'clicou',
      label: 'botao:gerar-fatura',
    };
    this.gtmService.pushTag(gtmTag);
  }

  public sendGTMButtonCopy() {
    const gtmTag = {
      event: 'eventGA',
      category: 'portal:acordo',
      action: 'clicou',
      label: 'copiar:codigo-de-barras',
    };
    this.gtmService.pushTag(gtmTag);
  }

  openModalDischarge() {
    this.isLoading = true;
    this.arrangementService
      .getDischardById(this.arrangement.BindingId)
      .toPromise()
      .then((res) => {
        this.isLoading = false;
        const modalRef = this.modalService.open(DischargeModalComponent, {
          size: 'lg',
        });
        modalRef.componentInstance.dischargeDetail = {
          ...res,
          bindingId: this.arrangement.BindingId,
        };
      })
      .catch(() => {
        this.isLoading = false;
      });
  }
}
