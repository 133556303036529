import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss']
})
export class OnboardingComponent {

  item = 1;

  constructor(
    private activeModal: NgbActiveModal
  ) { }

  goToNext() {
    this.item++;
  }

  close() {
    this.activeModal.close('FINISH_ONBOARDING');
  }
}
