import { Injectable } from '@angular/core';
import decode from 'jwt-decode';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class SessionManagerService {
  userProps: any;
  request: string;
  decrypted: string;
  encrypted: any;
  tokenFromUI = '0123456789123456';

  constructor() { }

  getProp() {
    if (
      sessionStorage.getItem('returnIpanema') &&
      !sessionStorage.getItem('returnIpanemaSession')
    ) {
      let token = sessionStorage.getItem('returnIpanema');
      let tokenDecoded = decode(JSON.parse(token).Token);

      this.userProps = {
        email: tokenDecoded['email'],
        name: tokenDecoded['name'],
        phone_number: tokenDecoded['phone_number'],
      };

      this.encryptUsingAES256(JSON.stringify(this.userProps));
    } else if (sessionStorage.getItem('returnIpanemaSession')) {
      let returnIpanemaSession = sessionStorage.getItem('returnIpanemaSession');
      let user = this.decryptUsingAES256(returnIpanemaSession.toString());
      let userParsed = JSON.parse(JSON.parse(user));

      this.userProps = {
        email: userParsed['email'],
        name: userParsed['name'],
        phone_number: userParsed['phone_number'],
      };
    }
    return this.userProps;
  }

  setProp(prop, value) {
    this.userProps[`${prop}`] = value;
    this.encryptUsingAES256(JSON.stringify(this.userProps));
  }

  encryptUsingAES256(value) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(JSON.stringify(value), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    sessionStorage.setItem('returnIpanemaSession', encrypted);
    return encrypted.toString();
  }

  decryptUsingAES256(temp) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);

    this.decrypted = CryptoJS.AES.decrypt(temp, _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    }).toString(CryptoJS.enc.Utf8);
    return this.decrypted;
  }
}
