import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AbstractService } from './abstract.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService extends AbstractService {

  model = 'profiles';

  updateName(data): any {
    return this.http.patch(`${environment.apiUrl}/${this.model}/me`, data);
  }

  updatePassword(data): any {
    return this.http.put(`${environment.apiUrl}/${this.model}/password`, data);
  }

  /** Change email on PROFILE page (LOGGED AREA) */
  updateEmail(data): any {
    return this.http.put(`${environment.apiUrl}/${this.model}/email`, data);
  }

  /** Confirm email on PROFILE page (LOGGED AREA) */
  confirmEmail(data): any {
    return this.http.patch(`${environment.apiUrl}/${this.model}/email`, data);
  }

  /** Change phone on PROFILE page (LOGGED AREA) */
  updatePhone(data): any {
    return this.http.put(`${environment.apiUrl}/${this.model}/phone-number`, data);
  }

  /** Confirm phone on PROFILE page (LOGGED AREA) */
  confirmPhone(data): any {
    return this.http.patch(`${environment.apiUrl}/${this.model}/phone-number`, data);
  }

  // ------------------------------------ PUBLIC ------------------------------------ //

  /** Change phone while register user (PUBLIC AREA) */
  updatePhonePublicArea(data): any {
    return this.http.post(`${environment.apiUrlV2}/${this.model}/phone-number`, data);
  }

  /** Confirm phone while register user (PUBLIC AREA) */
  confirmPhonePublicArea(data): any {
    return this.http.patch(`${environment.apiUrlV2}/${this.model}/phone-number`, data);
  }
}