export interface PaymentConfirmation {
  expirationDate: string,
  installmentID: number,
  status: PaymentStatusTypes,
  txid: string,
  value: number
}


export enum PaymentStatusTypes {
  PAID = 61001,
  PARCIAL_PAYD = 61005
}
