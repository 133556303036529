<div class="modal-body">
    <div class="row">
        <div class="col-12 text-center">
            <h3>Obrigado!</h3>
        </div>
    </div>
    <div class="row">
        <div class="col-12 text-center">
            <svg class="check-icon" class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
            </svg>
        </div>
    </div>
    <div class="row">
        <div class="col-12 text-center">
            <p *ngIf="status == paymentStatusTypes.PAID">
                O seu pagamento foi recebido! <br />
            </p>
            <p *ngIf="status == paymentStatusTypes.PARCIAL_PAYD">
                O seu pagamento foi recebido!<br />
                <small>O pagamento não corresponde ao valor da parcela, portanto este acordo será cancelado e o valor
                    pago será abatido do saldo da sua dívida.</small>
            </p>
        </div>
    </div>
    <div class="row pt-4">
        <div class="col-12 text-center">
            <button class="button primary" (click)="close()">
                {{ 'BUTTONS.CONTINUE' | translate }}
            </button>
        </div>
    </div>
</div>