import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GoogleAnalyticsService } from '../../../services/google-analytics.service';
let gtag: Function;

@Component({
  selector: 'app-terms-and-services',
  templateUrl: './terms-and-services.component.html',
  styleUrls: ['./terms-and-services.component.scss']
})
export class TermsAndServicesComponent implements OnInit {
  constructor(
    private activeModal: NgbActiveModal,
    private google: GoogleAnalyticsService
  ) { }

  ngOnInit() {
    this.google.sendEventWithProps('view_terms', {
      event_category: 'legal',
      event_label: 'terms_of_use'
    });
  }

  close(event) {

    event.preventDefault();
    event.stopPropagation();
    this.activeModal.close();
    // this.router.navigate(['/']);
  }
}
