import { Component } from '@angular/core';

@Component({
  templateUrl: 'corporation-swiper.component.html',
  selector: 'app-corporation-swiper',
  styleUrls: ['corporation-swiper.component.scss']
})
export class CorporationSwiperComponent {
  config = {
    slidesPerView: 1.0,
    pagination: { el: '.swiper-pagination', clickable: true },
    navigation: {
      nextEl: '#corporation-next',
      prevEl: '#corporation-prev'
    },
    spaceBetween: 30,
    breakpoints: {
      640: {
        slidesPerView: 2.0,
        spaceBetween: 30,
      },
      768: {
        slidesPerView: 2.0,
        spaceBetween: 30,
      },
      992: {
        slidesPerView: 2.0,
        spaceBetween: 30,
      },
      1024: {
        slidesPerView: 2.0,
        spaceBetween: 30,
      },
      1200: {
        slidesPerView: 5.0,
        spaceBetween: 30,
      },
      1920: {
        slidesPerView: 5.0,
        spaceBetween: 30,
      }
    }
  };
}
