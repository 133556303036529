import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-user-migration-modal',
  templateUrl: './user-migration-modal.component.html',
  styleUrls: ['./user-migration-modal.component.scss']
})
export class UserMigrationModalComponent implements OnInit {

  public canClose = false;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {

  }

  check(event) {
     this.canClose = event.target.checked;
  }

  close() {
    if (this.canClose) {
      this.activeModal.dismiss('Cross click');
    }
  }

}
