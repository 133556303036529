import { Component, Input, OnInit } from '@angular/core';
import { Arrangement } from '../../models/arrangement.model';

@Component({
  selector: 'app-arrangement-in-debt',
  templateUrl: './arrangement-in-debt.component.html',
  styleUrls: ['./arrangement-in-debt.component.scss']
})
export class ArrangementInDebtComponent implements OnInit {
  @Input() public arrangement: Arrangement;
  constructor() { }

  ngOnInit(): void {
  }

  getExpirationDay(date) {
    const dateFormated = new Date(date);
    return dateFormated.getDate();
  }

}
