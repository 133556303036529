import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastService } from '../../../services/toast.service';
import { Router } from '@angular/router';
import { ProfileService } from '../../../services/profile.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { GoogleAnalyticsService } from '../../../services/google-analytics.service';
import decode from 'jwt-decode';
import { SessionManagerService } from '../../utils/service/session-manager.service';
import { MetaPixelService } from 'src/app/services/meta-pixel.service';

@Component({
  selector: 'app-profile-modal',
  templateUrl: './profile-modal.component.html',
  styleUrls: ['./profile-modal.component.scss'],
})
export class ProfileModalComponent implements OnInit {
  label: string;
  control: string;
  type: string;
  value: string;
  newValue: string;
  header: string;
  placeholder: string;
  validator: number;
  loading = false;
  confirm = false;
  username: string;
  password;
  show = false;

  formInputs: [
    {
      controlName: string;
      id: string;
      label: string;
      placeholder: string;
      visible: boolean,
      type: string;
    },
  ];
  profileForm: FormGroup;

  confirmInputs: [
    {
      controlName: string;
      id: string;
      label: string;
      placeholder: string;
      type: string;
      visible: boolean;
    }
  ];
  profileConfirm: FormGroup;
  passwordType: string;
  passwordSaved: any;

  constructor(
    private activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private profileService: ProfileService,
    private toastService: ToastService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private sessionManager: SessionManagerService,
    private metaService: MetaPixelService
  ) { }

  ngOnInit(): void {
    this.password = 'password';

    this.formInputs = [
      {
        id: 'input',
        type: this.type,
        label: this.label,
        controlName: 'input',
        placeholder: this.placeholder,
        visible: true,
      },

    ];

    this.profileForm = this.fb.group({
      input: [
        JSON.parse(JSON.stringify(this.value)),
        Validators.compose([
          Validators.required,
          Validators.minLength(this.validator),
        ]),
      ],
    });
    this.sessionManager.getProp();
  }

  getInputValue(ev: any): void {

    this.profileForm.controls[ev.name].setValue(ev.value);
  }

  getInputConfirm(ev: any): void {
    this.profileConfirm.controls[ev.name].setValue(ev.value);
  }

  close() {
    this.profileForm.reset();
    if (this.confirm) {
      this.profileConfirm.reset();
    }
    this.activeModal.close();
  }

  onSubmit() {
    this.loading = true;

    this.googleAnalyticsService.sendClickEvent(
      'dashboard',
      'btn_update_confirmation'
    );

    switch (this.control) {
      case 'name':
        this.updateName(this.profileForm);
        break;

      case 'password':
        this.updatePassword(this.profileForm);
        break;

      case 'email':
        this.updateEmail(this.profileForm);
        this.metaService.trackContact();
        break;

      case 'phone':
        this.updatePhone(this.profileForm);
        this.metaService.trackContact();
        break;

      case 'confirmEmail':
        this.updateConfirmEmail(this.profileConfirm);
        break;

      case 'confirmPhone':
        this.updateConfirmPhone(this.profileConfirm);
        break;
    }
  }

  updateName(form) {
    this.metaService.trackLead()
    this.profileService
      .updateName({
        Name: form.controls.input.value,
        Password: (document.getElementById('confirmPassowrd') as HTMLInputElement).value
      })
      .subscribe(
        (data: any) => {
          this.sessionManager.setProp('name', form.controls.input.value);
          this.loading = false;
          this.close();
          this.toastService.show({
            body: 'Alteração realizada com sucesso.',
            class: 'bg-success',
          });
          window.location.reload();
        },
        (err) => {
          this.loading = false;
        }
      );
  }

  updatePassword(form) {
    this.metaService.trackLead()
    this.profileService
      .updatePassword({
        OldPassword: form.controls.input.value,
        NewPassword: (document.getElementById('confirmNewPassowrd') as HTMLInputElement).value
      })
      .subscribe(
        (data: any) => {
          this.loading = false;
          this.close();
          this.toastService.show({
            body: 'Alteração realizada com sucesso.',
            class: 'bg-success',
          });
          this.logout();
        },
        (err) => {
          this.loading = false;
        }
      );
  }

  updateEmail(form) {
    this.profileService
      .updateEmail({
        Email: form.controls.input.value,
        Password: (document.getElementById('confirmPassowrd') as HTMLInputElement).value
      })
      .subscribe(
        (data: any) => {
          this.newValue = form.controls.input.value;
          this.loading = false;
          this.confirm = true;
          this.control = 'confirmEmail';
          this.validator = 6;
          this.value = null;
          this.header = 'PROFILE.MODAL.INPUTS.CONFIRMMAIL.HEADER';
          this.passwordSaved = (document.getElementById('confirmPassowrd') as HTMLInputElement).value


          this.toastService.show({
            body:
              'O código de confirmação foi enviado para o seu email, verifique o lixo eletrônico.',
            class: 'bg-success',
          });

          this.confirmInputs = [
            {
              id: 'confirmEmail',
              type: 'text',
              label: 'PROFILE.MODAL.INPUTS.CONFIRMMAIL.LABEL',
              controlName: 'confirmEmail',
              placeholder: 'PROFILE.MODAL.INPUTS.CONFIRMMAIL.PLACEHOLDER',
              visible: true
            },
          ];

          this.confirmInputs = [
            {
              id: 'confirmEmail',
              type: 'text',
              label: 'PROFILE.MODAL.INPUTS.CONFIRMMAIL.LABEL',
              controlName: 'confirmEmail',
              placeholder: 'PROFILE.MODAL.INPUTS.CONFIRMMAIL.PLACEHOLDER',
              visible: true
            },
          ];

          this.profileConfirm = this.fb.group({
            confirmEmail: [
              '',
              Validators.compose([
                Validators.required
              ])
            ]
          });
        },
        (err) => {
          this.loading = false;
        });
  }

  updateConfirmEmail(form) {
    this.profileService.confirmEmail({
      ConfirmationCode: form.controls.confirmEmail.value,
      Username: this.username,
      Password: this.passwordSaved
    }).subscribe((data: any) => {
      this.sessionManager.setProp('email', this.newValue);
      this.loading = false;
      this.close();
      this.toastService.show({
        body: 'Alteração realizada com sucesso.',
        class: 'bg-success'
      });
      window.location.reload();
    },
      (err) => {
        this.loading = false;
      }
    );
  }

  formatPhoneNumber(number: string) {
    return '+55' + (number.replace(/\+55/g, '').replace(/[^0-9]/g, ''))
  }

  updatePhone(form) {
    const phoneNumber = this.formatPhoneNumber(form.controls.input.value);
    if (phoneNumber.length > 14) {
      this.toastService.show({
        body: 'O telefone deve conter no máximo 11 caracteres',
        class: 'bg-danger',
      });

      this.loading = false;
    } else {
      this.profileService
        .updatePhone({
          PhoneNumber: phoneNumber,
          Username: this.username,
          Password: (document.getElementById('confirmPassowrd') as HTMLInputElement).value
        })
        .subscribe(
          (data: any) => {
            this.newValue = form.controls.input.value;
            this.loading = false;
            this.confirm = true;
            this.value = null;
            this.control = 'confirmPhone';
            this.header = 'PROFILE.MODAL.INPUTS.CONFIRMPHONE.HEADER';
            this.validator = 6;
            this.passwordSaved = (document.getElementById('confirmPassowrd') as HTMLInputElement).value

            this.toastService.show({
              body:
                'O código de confirmação foi enviado para o seu telefone',
              class: 'bg-success',
            });

            this.confirmInputs = [
              {
                id: 'confirmPhone',
                type: 'text',
                label: 'PROFILE.MODAL.INPUTS.CONFIRMPHONE.LABEL',
                controlName: 'confirmPhone',
                placeholder: 'PROFILE.MODAL.INPUTS.CONFIRMPHONE.PLACEHOLDER',
                visible: true
              },
            ];

            this.profileConfirm = this.fb.group({
              confirmPhone: [
                '',
                Validators.compose([
                  Validators.required
                ])
              ]
            });
          },
          (err) => {
            this.loading = false;
          });
    }
  }

  updateConfirmPhone(form) {
    this.profileService.confirmPhone({
      ConfirmationCode: form.controls.confirmPhone.value,
      Username: this.username,
      Password: this.passwordSaved
    }).subscribe((data: any) => {
      this.sessionManager.setProp(
        'phone_number',
        this.newValue.replace(/[^0-9]/g, '')
      );
      this.loading = false;
      this.close();
      this.toastService.show({
        body: 'Alteração realizada com sucesso.',
        class: 'bg-success'
      });
      window.location.reload();
    },
      (err) => {
        this.loading = false;
      }
    );
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/']);
  }
  togglePasswordVisibility() {
    if (this.password === 'password') {
      this.password = 'text';
      this.show = true;
    } else {
      this.password = 'password';
      this.show = false;
    }
  }
}
