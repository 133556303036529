import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-status-installment',
  templateUrl: './status-installment.component.html',
  styleUrls: ['./status-installment.component.scss']
})
export class StatusInstallmentComponent implements OnInit, OnChanges {

  @Input() state: any;
  @Output() stateValue = new EventEmitter();

  public debtStatus = {
    cssClass: '',
    value: '',
  };

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    this.parseDebtStatus(this.state);
  }

  ngOnInit(): void {
    this.parseDebtStatus(this.state);
  }

  parseDebtStatus(status: string): void {
    if (status !== null) {
      this.debtStatus.cssClass = 'pay-off';
      this.debtStatus.value = 'PAGA';
    } else {
      this.debtStatus.cssClass = 'in-progress';
      this.debtStatus.value = 'EM ABERTO';
    }
    this.stateValue.emit(this.debtStatus.value);
  }

}
