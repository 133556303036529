import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-accept-proposal',
  templateUrl: './confirm-accept-proposal.component.html',
  styleUrls: ['./confirm-accept-proposal.component.scss']
})
export class ConfirmAcceptProposalComponent {

  constructor(
    private activeModal: NgbActiveModal
  ) { }

  close() {
    this.activeModal.close('close');
  }

}
