<app-toggle-button
  (clickAction)="click($event)"
  (blurAction)="blur()"
></app-toggle-button>
<ul *ngIf="toggled" class="list">
  <li class="first">
    <a href="#" routerLink="cadastrar">
      <strong>Cadastre-se</strong>
    </a>
  </li>
  <li class="second">
    <a href="#" routerLink="entrar"> Entrar </a>
  </li>
  <li>
    <a href="#" routerLink="contato"> Contato </a>
  </li>
</ul>
