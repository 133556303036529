
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { cpf, cnpj } from 'cpf-cnpj-validator';
import { CpfCnpjPipe } from '../../utils/pipes/cpfCnpj.pipe';

@Component({
  selector: 'app-box-input',
  templateUrl: './box-input.component.html',
  styleUrls: ['./box-input.component.scss'],
  providers: [CpfCnpjPipe],
})
export class BoxInputComponent implements OnInit {
  @Input() public id: string;
  @Input() public type: string;
  @Input() public label: string;
  @Input() public value: string;
  @Input() public placeholder: string;
  @Input() public hint: string;
  @Input() public disabled: boolean;
  @Input() public visible: boolean = true;
  @Input() private controlName: string;
  @Input() private autocomplete: string = '';
  @Input() public variant: string;
  public currentPassword: string;
  public hasError = false;

  @Output() public valueChanged = new EventEmitter();

  constructor(private cpfCnjp: CpfCnpjPipe) { }

  ngOnInit(): void {
    if (this.value) {
      this.valueChanged.emit({
        name: this.controlName,
        value:
          this.controlName == 'Username'
            ? this.cpfCnjp.transform(this.value)
            : this.value,
      });
    }
  }

  onInput(ev: any): void {
    switch (this.type) {
      case 'username':
      case 'identification':
        this.validateUsernameAndEmit(ev);
        break;
      case 'password':
      case 'visible-password':
        this.currentPassword = ev.target.value;
        this.validatePasswordAndEmit(ev);
        break;

      case 'text':
        this.validateNameAndEmit(ev);
        break;

      case 'phone':
        this.validatePhoneNumberAndEmit(ev);
        break;

      case 'sms-code':
        this.validateSmsCodeAndEmit(ev);
        break;

      default:
        this.setValue(ev);
        break;
    }
  }

  validateUsernameAndEmit(inputEvent: any): void {
    this.hasError = false;
    let value;

    if (inputEvent.target.id == 'signup-id') {
      value = inputEvent.target.value.replace(/\.|\-|\//g, '');
    } else {
      value = inputEvent.target.value;
    }

    if (!this.validateUsername(inputEvent.target.value)) {
      this.hasError = true;
    }
    value = inputEvent.target.value.replace(/\.|\-|\//g, '');
    const leng = value.length;
    console.log(leng);

    if (leng <= 11) {
      if (!cpf.isValid(value)) {
        this.hasError = true;
      }
    } else if (leng <= 14) {
      if (!cnpj.isValid(value)) {
        this.hasError = true;
      }
    }

    this.valueChanged.emit({
      name: this.controlName,
      value: inputEvent.target.value,
    });
  }

  validatePasswordAndEmit(inputEvent: any): void {
    this.hasError = false;

    if (!this.validatePassword(inputEvent.target.value)) {
      this.hasError = true;
    }

    this.valueChanged.emit({
      name: inputEvent.target.name,
      value: inputEvent.target.value,
    });
  }
  validateNameAndEmit(inputEvent: any): void {
    this.hasError = false;

    if (!this.validateName(inputEvent.target.value)) {
      this.hasError = true;
      inputEvent.target.value
    }

    this.valueChanged.emit({
      name: inputEvent.target.name,
      value: inputEvent.target.value,
    });
  }

  validateSmsCodeAndEmit(inputEvent: any): void {
    this.hasError = false;

    if (!this.validateSmsCode(inputEvent.target.value)) {
      this.hasError = true;
      return;
    }

    this.valueChanged.emit({
      name: inputEvent.target.name,
      value: inputEvent.target.value,
    });
  }

  validatePhoneNumberAndEmit(inputEvent: any): void {
    this.hasError = false;

    if (!this.validatePhoneNumber(inputEvent.target.value)) {
      this.hasError = true;
    }

    this.valueChanged.emit({
      name: inputEvent.target.name,
      value: inputEvent.target.value,
    });
  }

  setValue(inputEvent: any): void {
    this.valueChanged.emit({
      name: inputEvent.target.name,
      value: inputEvent.target.value,
    });
  }

  validateUsername(username: string): boolean {
    const regex =
      /([0-9]{3}\.[0-9]{3}\.[0-9]{3}-[0-9]{2})|([0-9]{2}\.[0-9]{3}\.[0-9]{3}\/[0-9]{4}-[0-9]{2})/;
    return regex.test(username);
  }

  validateName(name: string): boolean {
    const regex = /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ\s]+$/;
    return regex.test(name);
  }

  validatePassword(passsword: string): boolean {
    const regex = /^(?=.*[0-9])(?=.*[!@#$%^&*_-~])[a-zA-Z0-9!@#$%^&*_-~]{6,}$/;
    return regex.test(passsword);
  }

  validatePhoneNumber(phoneNumber: string): boolean {
    const regex = /^\(?[1-9]{2}\)? ?(?:[2-8]|9[1-9])[0-9]{3}\-?[0-9]{4}$/;
    return regex.test(phoneNumber);
  }

  togglePasswordVisibility() {
    this.type = this.type === 'password' ? 'visible-password' : 'password';
  }
  validateSmsCode(smsCode: string): boolean {
    const regex = /^[0-9]{6}$/;
    return regex.test(smsCode);
  }
}
