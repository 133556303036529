import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Debt } from '../../models/debt.model';
import { ToastService } from '../../../services/toast.service';
import { ArrangementService } from '../../../services/arrangement.service';
import {
  NgbCalendar,
  NgbDate,
  NgbDateStruct,
  NgbModal,
} from '@ng-bootstrap/ng-bootstrap';
import { ConfirmCustomProposalComponent } from '../confirm-custom-proposal/confirm-custom-proposal.component';
import { ConfirmAcceptProposalComponent } from '../confirm-accept-proposal/confirm-accept-proposal.component';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { PurchaseGA } from 'src/app/shared/models/purchase-event.model';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../../../services/authentication.service';
import { PaymentMethod } from '../../models/payment-method.constant';

@Component({
  selector: 'app-make-your-proposal',
  templateUrl: './make-your-proposal.component.html',
  styleUrls: ['./make-your-proposal.component.scss'],
})
export class MakeYourProposalComponent implements OnInit {
  @Input() public debt: Debt;
  @Input() public paymentMethod: PaymentMethod;
  @Output() public hideMakeYourProposal = new EventEmitter<boolean>();
  proposalForm: FormGroup;
  installments = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  today: string = moment().format('YYYY-MM-DD');
  loading = false;
  minDate: NgbDateStruct;
  maxDate: NgbDateStruct;
  markDisabled: any;
  customerInfo: any = {};
  datapick: any;
  sumBalance: number = 0;
  formSubmitted: boolean = false;
  @Output() public isSubmitted = new EventEmitter<boolean>();

  constructor(
    public authenticationService: AuthenticationService,
    private fb: FormBuilder,
    private toastService: ToastService,
    private arrangementService: ArrangementService,
    private modalService: NgbModal,
    private router: Router,
    private calendar: NgbCalendar,
    private translate: TranslateService,
    private analyticsService: AnalyticsService,
    private gtmService: GoogleTagManagerService
  ) {
    // habilitando os dados de final de semana
    //this.markDisabled = (date: NgbDate) => calendar.getWeekday(date) >= 6;

    const current = new Date();
    const maxDatePropostal = new Date();

    current.setDate(current.getDate());
    current.setMonth(current.getMonth());

    maxDatePropostal.setDate(
      maxDatePropostal.getDate() + environment.DiasLimitePagamento
    );
    maxDatePropostal.setMonth(maxDatePropostal.getMonth());

    this.minDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate(),
    };

    this.maxDate = {
      year: maxDatePropostal.getFullYear(),
      month: maxDatePropostal.getMonth() + 1,
      day: maxDatePropostal.getDate(),
    };
  }

  ngOnInit(): void {
    this.formSubmitted = false;
    this.isSubmitted.emit(false);

    document.all[0].setAttribute(
      'data-evt-page',
      '2c184162-f8ae-4ef1-94c2-62bae0a9fac2'
    );

    this.authenticationService.getCustomerInfo().then((rtn) => {
      this.customerInfo = rtn;
    });

    this.proposalForm = this.fb.group({
      DownPaymentAmount: [null, Validators.required],
      InstallmentAmount: [null, Validators.required],
      TotalInstallments: [null, Validators.required],
      FirstInstallmentDate: [null, Validators.required],
    });


    this.proposalForm.valueChanges.subscribe((changes) => {
      let downPayment = removeNonDigits(this.proposalForm?.controls?.DownPaymentAmount?.value);
      let installmAmount = removeNonDigits(this.proposalForm?.controls?.InstallmentAmount?.value);
      let totalInstallm = removeNonDigits(this.proposalForm?.controls?.TotalInstallments?.value);

      this.sumBalance = Number(downPayment) + (Number(installmAmount) * Number(totalInstallm));

      if (this.sumBalance > this.debt.DebtTotalBalance) {
        this.translate
          .get('DEBT.PROPOSAL.ERRORS.INCORRECT-VALUES-DEBT')
          .subscribe((res: string) => {
            this.toastService.show({
              body: res,
              class: 'bg-danger',
            });
          });
      };
    });

    function removeNonDigits(value) {
      if (value != null) {
        return value.replace(/[^0-9\,]/g, '').replace(/[\,]/g, '.');
      }
      return 0;
    }
  }

  hideMakeProposal() {
    this.hideMakeYourProposal.emit(false);

    const gtmTag = {
      event: 'eventGA',
      category: 'portal:proposta',
      action: 'clicou',
      label: 'botao:cancelar-proposta',
    };
    this.gtmService.pushTag(gtmTag);
  }

  validateDownPaymentAmount() {
    if (this.proposalForm.controls.DownPaymentAmount.value) {
      let DownPaymentAmount =
        this.proposalForm.controls.DownPaymentAmount.value.replace('.', '');
      DownPaymentAmount = DownPaymentAmount.replace(',', '.');
      DownPaymentAmount = DownPaymentAmount.replace('R$ ', '');

      if (DownPaymentAmount > this.debt.DebtTotalBalance) {
        this.translate
          .get('DEBT.PROPOSAL.ERRORS.INCORRECT-VALUES-DEBT')
          .subscribe((res: string) => {
            this.toastService.show({
              body: res,
              class: 'bg-danger',
            });
          });

        this.proposalForm.controls.DownPaymentAmount.setValue('');
      }
    }
  }

  validateInstallmentAmount() {
    if (this.proposalForm.controls.InstallmentAmount.value) {
      let InstallmentAmount =
        this.proposalForm.controls.InstallmentAmount.value.replace('.', '');
      InstallmentAmount = InstallmentAmount.replace(',', '.');
      InstallmentAmount = InstallmentAmount.replace('R$ ', '');

      if (InstallmentAmount > this.debt.DebtTotalBalance) {
        this.translate
          .get('DEBT.PROPOSAL.ERRORS.INCORRECT-VALUES-QUOTA')
          .subscribe((res: string) => {
            this.toastService.show({
              body: res,
              class: 'bg-danger',
            });
          });
        this.proposalForm.controls.InstallmentAmount.setValue('');
      }
    }
  }

  customDateAdapter = (value) => {
    return (
      value.year +
      '/' +
      ('0' + value.month).slice(-2) +
      '/' +
      ('0' + value.day).slice(-2)
    );
  };

  send() {
    this.formSubmitted = true;
    this.isSubmitted.emit(true);

    if (!this.proposalForm.valid) {

      this.toastService.show({
        body: 'Por favor, preencha todos os campos obrigatórios',
        class: 'bg-danger',
      });

    } else if (!this.paymentMethod || Number(this.paymentMethod) == 0) {
      document.getElementById("paymethod").scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest'
      })

      this.toastService.show({
        body: 'Por favor, selecione uma forma de pagamento',
        class: 'bg-danger',
      });

    } else if (this.sumBalance > this.debt.DebtTotalBalance) {
      this.toastService.show({
        body: 'O valor proposto não pode ser maior que o valor da dívida',
        class: 'bg-danger',
      });
    } else {
      let DownPaymentAmount =
        this.proposalForm.controls.DownPaymentAmount.value.replace('.', '');
      let InstallmentAmount =
        this.proposalForm.controls.InstallmentAmount.value.replace('.', '');
      DownPaymentAmount = DownPaymentAmount.replace(',', '.');
      DownPaymentAmount = DownPaymentAmount.replace('R$ ', '');
      InstallmentAmount = InstallmentAmount.replace(',', '.');
      InstallmentAmount = InstallmentAmount.replace('R$ ', '');

      let modalRef = this.modalService.open(ConfirmCustomProposalComponent, {
        centered: true,
        backdrop: true,
      });

      // Abre a modal
      modalRef.componentInstance.proposal = {
        DebtTotalBalance: this.debt.DebtTotalBalance,
        DownPaymentAmount: parseFloat(DownPaymentAmount),
        InstallmentAmount: parseFloat(InstallmentAmount),
        Portfolio: `${this.debt?.PortfolioName}-${this.debt?.PortfolioId}`,
        TotalInstallments: parseInt(
          this.proposalForm.controls.TotalInstallments.value
        ),
        InstallmentDay: parseInt(
          moment(this.proposalForm.controls.FirstInstallmentDate.value).format(
            'DD'
          )
        ),
        FirstInstallmentDate:
          this.proposalForm.controls.FirstInstallmentDate.value,
      };

      modalRef.componentInstance.date = moment(
        this.proposalForm.controls.FirstInstallmentDate.value
      );


      modalRef.componentInstance.currentBalance = this.debt.CurrentBalance;
      modalRef.componentInstance.paymentMethod = this.paymentMethod;


      modalRef.result.then((result) => {
        let InstallmentDay = this.customDateAdapter(
          this.proposalForm.controls.FirstInstallmentDate.value
        );
        this.proposalForm.controls['FirstInstallmentDate'].setValue(
          InstallmentDay
        );
        if (result == 'confirm') {
          this.loading = true;
          this.arrangementService
            .doPost({
              BindingId: this.debt.BindingId,
              Modality: 'DISCOUNT_VALUE',
              DiscountRate: 0,
              DownPaymentAmount: parseFloat(DownPaymentAmount),
              InstallmentAmount: parseFloat(InstallmentAmount),
              TotalInstallments:
                parseInt(this.proposalForm.controls.TotalInstallments.value) + 1,
              InstallmentDay: parseInt(
                moment(
                  this.proposalForm.controls.FirstInstallmentDate.value
                ).format('DD')
              ),
              FirstInstallmentDate: moment(
                this.proposalForm.controls.FirstInstallmentDate.value
              ),
              PaymentMethod: Number(this.paymentMethod)
            })
            .subscribe(
              (ret: any) => {
                this.loading = false;
                this.getArrangement(ret.ArrangementId, ret.IsApproved);
              },
              (err) => {
                this.loading = false;
              }
            );
        } else if (result === 'cancel') {
          this.loading = false;
          modalRef.close();
        }
      });

      const gtmTag = {
        event: 'eventGA',
        category: 'portal:proposta',
        action: 'clicou',
        label: 'botao:enviar-proposta',
      };
      this.gtmService.pushTag(gtmTag);
    }
  }

  getArrangement(id, isApproved) {
    this.loading = true;
    this.arrangementService.getArrangementById(id).subscribe(
      (res: any) => {
        if (
          res.ArrangementStatus === 'REVIEW' ||
          res.ArrangementStatus === 'WAITING'
        ) {
          this.analyticsService.sendPurchaseAnalytics(res, PurchaseGA.Exception);
        } else {
          this.analyticsService.sendPurchaseAnalytics(res, PurchaseGA.Open);
        }
        this.loading = false;
        if (isApproved) {
          this.router.navigate([`/painel/divida/acordo-concluido/proposta`]);
        } else {
          this.router.navigate([`/painel/divida/acordo-pendente/proposta`]);
        }
      },
      (err) => {
        this.loading = false;
      });
  }

  public entradaInputGTM() {
    const gtmTag = {
      event: 'eventGA',
      category: 'portal:proposta',
      action: 'preencheu',
      label: 'input:valor-entrada',
    };
    this.gtmService.pushTag(gtmTag);
  }

  public valorMensalInputGTM() {
    const gtmTag = {
      event: 'eventGA',
      category: 'portal:proposta',
      action: 'preencheu',
      label: 'input:valor-parcela',
    };
    this.gtmService.pushTag(gtmTag);
  }

  public parcelasSelectGTM() {
    const gtmTag = {
      event: 'eventGA',
      category: 'portal:proposta',
      action: 'clicou',
      label: 'select:parcelas',
    };
    this.gtmService.pushTag(gtmTag);
  }

  public dataVencimentoInputGTM() {
    const gtmTag = {
      event: 'eventGA',
      category: 'portal:proposta',
      action: 'clicou',
      label: 'select:data-vencimento',
    };
    this.gtmService.pushTag(gtmTag);
  }
}
