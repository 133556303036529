import { Component, HostListener, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { PaymentMethod } from '../../models/payment-method.constant';

@Component({
  selector: 'app-accept-proposal',
  templateUrl: './accept-proposal.component.html',
  styleUrls: ['./accept-proposal.component.scss'],
})
export class AcceptProposalComponent {
  @Input() negotiation: any;
  @Input() date: any;
  @Input() currentBalance: number;
  @Input() paymentMethod: PaymentMethod;
  @Input() couponName;
  @Input() proposal: any;
  customerInfo: any = {};

  @HostListener('click', ['$event'])
  clickEvent(event) {
    event.srcElement.setAttribute('disabled', true);
  }

  constructor(
    private activeModal: NgbActiveModal,
    private gtmService: GoogleTagManagerService,
    private authenticationService: AuthenticationService
  ) {
    this.authenticationService.getCustomerInfo().then((rtn) => {
      this.customerInfo = rtn;
    });
  }

  close() {
    this.activeModal.close('cancel');

    const gtmTag = {
      event: 'eventGA',
      category: 'portal:proposta',
      action: 'clicou',
      label: 'botao:cancelar',
    };
    this.gtmService.pushTag(gtmTag);
  }

  confirm() {
    this.activeModal.close('confirm');

    const gtmTag = {
      event: 'eventGA',
      category: 'portal:proposta',
      action: 'clicou',
      label: 'botao:confirmar-enviar',
    };
    this.gtmService.pushTag(gtmTag);
  }
}
