<div class="modal-body">
  <div class="row">
    <div class="col-12 mb-4">
      <h3>{{ 'DEBT.PROPOSAL.RESUME' | translate }}</h3>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <h5>{{ 'DEBT.PROPOSAL.ACTUAL-DEBT' | translate }}</h5>
    </div>
  </div>
  <div class="row pt-1">
    <div class="col">
      <h6>{{ 'DEBT.ORIGINAL-VALUE' | translate }}</h6>
    </div>
    <div class="col text-right">
      <strong>{{
        currentBalance | currency: 'BRL':'R$':'1.2-2'
        }}</strong>
    </div>
  </div>
  <div class="row pt-1 pb-3">
    <div class="col">
      <h6>{{ 'DEBT.ACTUAL-VALUE' | translate }}</h6>
    </div>
    <div class="col text-right">
      <strong>{{
        proposal?.DebtTotalBalance | currency: 'BRL':'R$':'1.2-2'
        }}</strong>
    </div>
  </div>
  <div class="row separator pt-3">
    <div class="col">
      <h5>{{ 'DEBT.PROPOSAL.CONFIRM-RESUME' | translate }}</h5>
    </div>
  </div>
  <div class="row pt-1">
    <div class="col text-right">
      <strong class="tag-discount">
        {{ (((proposal.DebtTotalBalance - getTotalValue()) * 100) / proposal.DebtTotalBalance) | number: '1.2-2' }} %
        Desconto
      </strong>
    </div>
  </div>
  <div class="row pt-1">
    <div class="col">
      <h6>{{ 'DEBT.PROPOSAL.PAYMENT-METHOD' | translate }}</h6>
    </div>
    <div class="col text-right">
      <strong *ngIf="paymentMethod == 3"> {{ 'DEBT.PROPOSAL.BOLETO' | translate }}</strong>
      <strong *ngIf="paymentMethod == 11"> {{ 'DEBT.PROPOSAL.PIX' | translate }}</strong>
    </div>
  </div>
  <div class="row pt-1">
    <div class="col">
      <h6>{{ 'DEBT.PROPOSAL.FIRST-INSTALLMENT' | translate }}</h6>
    </div>
    <div class="col text-right">
      <strong>
        {{ proposal?.DownPaymentAmount | currency: 'BRL':'R$':'1.2-2' }}
      </strong>
    </div>
  </div>
  <div class="row pt-1">
    <div class="col">
      <h6>{{ 'DEBT.PROPOSAL.INSTALMMENTS' | translate }}</h6>
    </div>
    <div class="col text-right">
      <strong>
        {{ proposal?.TotalInstallments }}x de
        {{ proposal?.InstallmentAmount | currency: 'BRL':'R$':'1.2-2' }}
      </strong>
    </div>
  </div>
  <div class="row pt-1">
    <div class="col">
      <h6>{{ 'DEBT.PROPOSAL.TOTAL-VALUE' | translate }}</h6>
    </div>
    <div class="col total-value text-right">
      <strong>{{ getTotalValue() | currency: 'BRL':'R$':'1.2-2' }}</strong>
    </div>
  </div>
  <div class="row end-date align-items-center mt-3 mb-3">
    <div class="col p-0">
      <h6>{{ 'DEBT.PROPOSAL.FIRST-END-DATE' | translate }}</h6>
    </div>
    <div class="col p-0 d-flex align-items-center justify-content-end text-right">
      <strong>{{ firstInstallmentDate | date: 'dd/MM/yyyy' }}</strong>
    </div>
  </div>
  <div class="row">
    <div class="col-12 p-0 text-right">
      <button class="button outline mr-3 fechar-acordo-cancelar {{proposal.Portfolio}}" id="Cancelar confirmação acordo"
        data-evt-collect="true" data-evt-category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38"
        data-evt-type="18bde62f-bc03-4c32-96fe-bf58df7b81c4" attr.data-evt-context-id="{{ customerInfo }}"
        data-evt-element-id="Cancelar" (click)="close()">
        {{ 'BUTTONS.CANCEL' | translate }}
      </button>
      <button class="button primary fechar-acordo-confirmar {{proposal.Portfolio}}" id="Confirmar acordo"
        data-evt-collect="true" data-evt-category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38"
        data-evt-type="18bde62f-bc03-4c32-96fe-bf58df7b81c4" attr.data-evt-context-id="{{ customerInfo }}"
        data-evt-element-id="Confirmar envio da proposta" (click)="confirm()">
        {{ 'BUTTONS.CONFIRM' | translate }}
      </button>
    </div>
  </div>
</div>