import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-pop-up-pix',
  templateUrl: './pop-up-pix.component.html',
  styleUrls: ['./pop-up-pix.component.scss']
})
export class PopUpPixComponent implements OnInit {

  customerInfo: any = {};
  constructor(private activeModal: NgbActiveModal, private router: Router, private authenticationService: AuthenticationService) { }

  ngOnInit(): void {
    this.authenticationService.getCustomerInfo().then((rtn) => {
      this.customerInfo = rtn;
    });
  }


  close() {
    this.activeModal.close();
    sessionStorage.setItem('popup', 'true');
  }

  goTo() {
    this.close();
    this.router.navigate(['/painel/dividas']);
  }


}
