import { Injectable, Component } from '@angular/core';
import { Gtag } from 'angular-gtag';

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {
  constructor(private google: Gtag) { }

  // Generics and Defaults
  async sendPageView(title, path, url) {
    if (sessionStorage.getItem('analytics') == 'yes') {
      this.google.pageview({
        page_title: title,
        page_path: path,
        page_location: url
      });
    }
  }

  async sendEvent(type, category, label) {
    if (sessionStorage.getItem('analytics') == 'yes') {
      this.google.event(type, {
        event_category: category,
        event_label: label
      });
    }
  }

  async sendEventWithProps(type, props) {
    if (sessionStorage.getItem('analytics') == 'yes') {
      this.google.event(type, { ...props });
    }
  }

  async sendClickEvent(category, label) {
    this.google.event('click', {
      event_category: category,
      event_label: label
    });
  }

}
