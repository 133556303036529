import { Component, OnInit } from '@angular/core';
import { GenericList } from '../../models/generic-list.model';

@Component({
  selector: 'app-segment-carousel',
  templateUrl: './segment-carousel.component.html',
  styleUrls: ['./segment-carousel.component.scss']
})
export class SegmentCarouselComponent implements OnInit {

  segments: GenericList[] = [
    {
      name: 'Varejo',
      id: 1,
      icon: 'fa-house'
    },
    {
      name: 'Banco',
      id: 2,
      icon: 'fa-house'
    },
    {
      name: 'Veículos',
      id: 3,
      icon: 'fa-house'
    },
    {
      name: 'Serviços',
      id: 4,
      icon: 'fa-house'
    }
  ];

  itemSelected = null;

  constructor() { }

  ngOnInit(): void {
  }

  select(item: GenericList) {
    // this.itemSelected = item;
  }

}
