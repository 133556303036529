<app-loading [show]="loading"></app-loading>
<div class="auth-content">
  <section class="row inside-content">
    <section class="top-container col-lg-6 col-md-6 col-sm-12 d-flex flex-column justify-content-between">
      <div class="welcome-cross">
        <h3 class="title" [innerHTML]="'SIGNUP.TITLE-CROSS-SANTANDER' | translate"></h3>
        <h3 class="title" [innerHTML]="'SIGNUP.TITLE-CROSS-SANTANDER-2' | translate"></h3>
        <br />
        <p class="subtitle" *ngIf="subtitle">
          {{ 'SIGNUP.SUBTITLE-CROSS-SANTANDER-1' | translate }} <span class="highlight">{{
            'SIGNUP.SUBTITLE-CROSS-SANTANDER-2' | translate }}</span>{{
          'SIGNUP.SUBTITLE-CROSS-SANTANDER-3' | translate }}
        </p>
        <br />
        <!-- <div class="logos">
          <img class="logo" src="./assets/images/logo.svg" width="100" alt="logo Ipanema" rel="preload" />
        </div> -->
        <img class="santander-img" src="./assets/images/mobile-cadastrar-ipanema.svg" width="400"
          alt="Imagem de um celular com o app santander na mão de uma pessoa" rel="preload" />
      </div>
      <br />
    </section>

    <section class="content-container col-lg-5 col-md-5 col-sm-12">
      <ng-content></ng-content>
    </section>
  </section>

  <section class="section-steps">
    <h4> Veja como é facil</h4>
    <div class="steps">
      <div class="step">
        <span class="number">1</span>
        <p>Insira os seus dados e crie sua senha</p>
      </div>
      <div class="step">
        <span class="number">2</span>
        <p>Veja suas ofertas e renegocie</p>
      </div>
      <div class="step">
        <span class="number">3</span>
        <p>Pague o seu acordo em dia</p>
      </div>
      <div class="step">
        <span class="number">4</span>
        <p>Abra sua conta com a Gente! <br />
          *Atualização semanal do seu acordo fechado
        </p>
      </div>
    </div>
  </section>

  <section class="section-library">
    <h4> Confira conteúdos que podem te ajudar a não ter mais esse problema </h4>
    <div class="steps">
      <div class="knowmore">
        <div>
          <img class="logo" src="./assets/images/tumb-santander-1.png" width="250" alt="logo Ipanema" rel="preload" />
          <p>Como renegociar dívida online de maneira fácil, rápida e segura?</p>
        </div>
        <a rel="noopener" target="_blank"
          href="https://www.santander.com.br/blog/negociar-online/?utm_source=return&utm_medium=referral&utm_content=blog:santander">Saiba
          mais</a>
      </div>
      <div class="knowmore">
        <div>
          <img class="logo" src="./assets/images/tumb-santander-2.png" width="250" alt="logo Ipanema" rel="preload" />
          <p>Empréstimo pessoal online: mais simples, rápido e seguro</p>
        </div>
        <a rel="noopener" target="_blank"
          href="https://www.santander.com.br/blog/emprestimo-pessoal-online/?utm_source=return&utm_medium=referral&utm_content=blog:santander">Saiba
          mais</a>
      </div>
      <div class="knowmore">
        <div>
          <img class="logo" src="./assets/images/tumb-santander-3.png" width="250" alt="logo Ipanema" rel="preload" />
          <p>O que é Score de Crédito? <br /> Entenda como essa pontuação impacta sua vida financeira</p>
        </div>
        <a rel="noopener" target="_blank"
          href="https://www.santander.com.br/blog/score-credito-entenda/?utm_source=return&utm_medium=referral&utm_content=blog:santander">Saiba
          mais</a>
      </div>
    </div>
  </section>
</div>