import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ArrangementService } from 'src/app/services/arrangement.service';
import { ToastService } from 'src/app/services/toast.service';
import { AuthenticationService } from '../../../services/authentication.service';

@Component({
  selector: 'app-discharge-modal',
  templateUrl: './discharge-modal.component.html',
  styleUrls: ['./discharge-modal.component.scss'],
})
export class DischargeModalComponent implements OnInit {
  @Input() dischargeDetail: any;
  public isLoading = false;
  customerInfo: any = {};

  constructor(
    public authenticationService: AuthenticationService,
    public activeModal: NgbActiveModal,
    private arrangementService: ArrangementService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.authenticationService.getCustomerInfo().then((rtn) => {
      this.customerInfo = rtn;
    });
  }

  download() {
    this.isLoading = true;
    this.activeModal.close();
    this.arrangementService
      .getDischardLetterFile(this.dischargeDetail.bindingId)
      .toPromise()
      .then((res) => {
        this.isLoading = false;
        this.activeModal.close();
        let today = new Date();
        let downloadURL = window.URL.createObjectURL(res);
        let link = document.createElement('a');
        link.href = downloadURL;
        link.download = `${
          this.dischargeDetail.bindingId
        }-${today.toLocaleDateString()}.pdf`;
        link.click();
      })
      .catch(() => {
        this.isLoading = false;
      });
  }

  sendToEmail() {
    this.isLoading = true;
    this.arrangementService
      .getDischardLetterEmail(this.dischargeDetail.bindingId)
      .toPromise()
      .then((res) => {
        this.isLoading = false;
        this.activeModal.close();
        this.toastService.show({
          body: 'Enviado para seu E-mail.',
          class: 'bg-success',
        });
      })
      .catch(() => {
        this.isLoading = false;
      });
  }
}
