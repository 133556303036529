<div class="debt-agreement-div">
  <div class="row card-details">
    <div class="col-md-4">
      <div class="card">
        <div class="card-icon">
          <img src="/assets/images/icon-file.png" alt="file icon" rel="preload" />
        </div>
        <div class="card-text">
          <p class="label">
            {{ 'ARRANGEMENTS.DETAILS.AGREEMENT-NUMBER' | translate }}
          </p>
          <p class="desc">{{ arrangement?.ArrangementId }}</p>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="card">
        <div class="card-icon">
          <img src="/assets/images/icon-dollar.png" alt="file icon" rel="preload" />
        </div>
        <div class="card-text">
          <p class="label">
            {{ 'ARRANGEMENTS.DETAILS.PAYMENT-METHOD' | translate }}
          </p>
          <p class="desc">{{ arrangement?.ArrangementDescription }}</p>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="card">
        <div class="card-icon">
          <img src="/assets/images/icon-calendar.png" alt="file icon" rel="preload" />
        </div>
        <div class="card-text">
          <p class="label">
            {{ 'ARRANGEMENTS.DETAILS.EXPIRES-DAY' | translate }}
          </p>
          <p class="desc">
            {{ getExpirationDay(installment?.RemitDueDate) | date: 'dd/MM/yyyy' }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <h6 class="title">
    {{ 'DEBT.INVOICE-TITLE' | translate }}
  </h6>
  <div class="invoice-detail">
    <div class="row">
      <div class="col-sm-12 col-md-7">
        <div class="input-select-ipanema">
          <label>{{ 'ARRANGEMENTS.DETAILS.SELECT-MONTH-INVOICE' | translate }}</label>
          <div ngbDropdown class="d-inline-block dropdown-div">
            <button class="btn" id="dropdownBasic1" ngbDropdownToggle>
              {{ correctInvoiceTitle }}
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <button ngbDropdownItem *ngFor="
                let installment of arrangement.ArrangementInstallmentResponseList
              " (click)="setInstallment(installment)">
                {{ getMonthNameAndYear(installment.RemitDueDate) }} -
                {{ installment.SettlementDate != null ? 'PAGA' : 'EM ABERTO' }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-5 pt-4 pt-md-4 pt-lg-4">
        <div class="remaining-installments">
          <p class="text">
            {{ (arrangement.TotalInstallments - arrangement.PaidInstallments) + ' de ' + arrangement.TotalInstallments +
            ' restantes' }}
            <i class="fa fa-info-circle"></i>
          </p>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="installment">
      <div class="col-md-12">
        <div class="col-md-12 card-invoice-detail">
          <div class="row align-items-center pb-3">
            <div class="col">
              <h4 class="bold m-0"> {{ 'ARRANGEMENTS.DETAILS.INVOICE' | translate }}</h4>
            </div>
            <div class="col col-auto text-right">
              <app-status-installment [state]="installment.SettlementDate"></app-status-installment>
            </div>
          </div>
          <div class="row">
            <!-- Left side -->
            <div class="col col-lg-8">
              <div class="row justify-content-between">
                <div class="col-12" [ngClass]="{'col-12': !isQRCodeVisible, 'col': isQRCodeVisible}">
                  <label class="m-0">
                    {{ 'ARRANGEMENTS.DETAILS.INVOICE-AMOUNT' | translate }}
                  </label>
                  <p [class]="installment.SettlementDate != null ? 'invoice-value closed': 'invoice-value open'">
                    {{ installment.InstallmentAmount | currency: 'BRL':'R$' }}
                  </p>
                </div>
                <div class="align-self-center col" *ngIf="isQRCodeVisible">
                  <app-timer [time]="180000" (notify)="hideQRCode($event)" (notifyEach)="checkPayment($event)">
                  </app-timer>
                </div>
              </div>
              <div class="row" *ngIf="installment.RemitDueDate != null">
                <div class="col-12">
                  <div class="invoice-desc">
                    <strong class="label-text">
                      {{ 'ARRANGEMENTS.DETAILS.INVOICE-DATE' | translate }}
                    </strong>
                    <p class="value-text">{{ installment?.RemitDueDate | date }}</p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <!-- Para boletos -->
                  <div class="invoice-desc"
                    *ngIf="installment.BilletLine != null && installment.BilletLine != '' && arrangement?.DefaultPaymentMethod != 11">
                    <strong class="label-text">
                      {{ 'ARRANGEMENTS.DETAILS.BILLET-LINE' | translate }}
                    </strong>
                    <div class="clipboard" *ngIf="!coppied">
                      <img src="/assets/images/fi_copy.svg" alt="clipboard" class="clipboard-icon" rel="preload" />
                      <p id="btn_copy_barcode" data-evt-collect="true"
                        data-evt-category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38"
                        data-evt-type="5e48115d-6ada-4ff5-a140-09b9e963b0b1"
                        attr.data-evt-context-id="{{ customerInfo }}" data-evt-element-id="Copiar código boleto"
                        class="clipboard-label" (click)="copyToClipboard(installment.BilletLine)">
                        {{ 'ARRANGEMENTS.DETAILS.COPY' | translate }}
                      </p>
                    </div>
                    <div class="clipboard" *ngIf="coppied" (click)="sendGTMButtonCopy()">
                      <p class="clipboard-label coppied">
                        {{ 'ARRANGEMENTS.DETAILS.COPPIED' | translate }}
                      </p>
                    </div>
                    <p class="value-text">{{ installment.BilletLine }}</p>
                  </div>
                  <!-- Para PIX -->
                  <div class="invoice-desc pix"
                    *ngIf="installment.BilletLine != null && installment.BilletLine != '' && arrangement?.DefaultPaymentMethod == 11 && isQRCodeVisible">
                    <div>
                      <strong class="label-text">
                        {{ 'ARRANGEMENTS.DETAILS.BILLET-PIX' | translate }}
                      </strong>
                      <div class="clipboard" *ngIf="!coppied">
                        <img src="/assets/images/fi_copy.svg" alt="clipboard" class="clipboard-icon" rel="preload" />
                        <p id="btn_copy_barcode" data-evt-collect="true"
                          data-evt-category="f3682a46-8e88-486a-ae4a-1b3ef41f4d38"
                          data-evt-type="5e48115d-6ada-4ff5-a140-09b9e963b0b1"
                          attr.data-evt-context-id="{{ customerInfo }}" data-evt-element-id="Copiar código PIX"
                          class="clipboard-label" (click)="copyToClipboard(installment.BilletLine)">
                          {{ 'ARRANGEMENTS.DETAILS.COPY' | translate }}
                        </p>
                      </div>
                      <div class="clipboard" *ngIf="coppied" (click)="sendGTMButtonCopy()">
                        <p class="clipboard-label coppied">
                          {{ 'ARRANGEMENTS.DETAILS.COPPIED' | translate }}
                        </p>
                      </div>
                      <div class="code-expand-container" [ngClass]="{'opened': installment.opened}"
                        (click)="!installment.opened && (installment.opened = !installment.opened)">
                        <p class="value-text billet pix-code">{{ installment.BilletLine }} </p>
                      </div>
                      <div class="expand-button-container">
                        <small class="expand-button" (click)="installment.opened = !installment.opened">
                          Ver <ng-container *ngIf="installment.opened">menos</ng-container>
                          <ng-container *ngIf="!installment.opened">mais</ng-container>
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Right side -->
            <div class="col col-lg-4"
              *ngIf="installment.SettlementDate == null && arrangement?.DefaultPaymentMethod == 11">
              <div class="height-100 d-flex justify-content-end align-items-end">
                <div *ngIf="!isQRCodeVisible && arrangement.NextInstallment == installment.InstallmentNumber">
                  <button class="button primary" id="btn_payment" data-evt-collect="true"
                    data-evt-category="ca7650f8-eef8-43f8-a504-582889bb0748"
                    data-evt-type="18bde62f-bc03-4c32-96fe-bf58df7b81c4" attr.data-evt-context-id="{{ customerInfo }}"
                    data-evt-element-id="Ver QR Code" (click)="showQRCode()">
                    {{ 'ARRANGEMENTS.DETAILS.VIEW-QR' | translate }}
                  </button>
                </div>
                <div class="qr-code" *ngIf="isQRCodeVisible">
                  <img class="image-company qr-code" *ngIf="installment?.QRCode" [src]="installment.QRCode"
                    alt="QRCode PIX" rel="preload" />
                </div>
              </div>
            </div>
            <div class="col col-lg-4"
              *ngIf="installment.SettlementDate == null && installment.BilletLine != null && arrangement?.DefaultPaymentMethod != 11">
              <div class="height-100 d-flex justify-content-end align-items-end">
                <div>
                  <button class="button primary" id="btn_bankslip" data-evt-collect="true"
                    data-evt-category="ca7650f8-eef8-43f8-a504-582889bb0748"
                    data-evt-type="18bde62f-bc03-4c32-96fe-bf58df7b81c4" attr.data-evt-context-id="{{ customerInfo }}"
                    data-evt-element-id="Gerar Fatura" (click)="generateLastBill()">
                    {{ 'ARRANGEMENTS.DETAILS.VIEW-INVOICE' | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- carta de quitação -->
      <div class="col-md-12" style="text-align: right;"
        *ngIf="arrangement.PaidInstallments == arrangement.TotalInstallments">
        <button class="button primary letter-button" data-evt-collect="true"
          data-evt-category="ca7650f8-eef8-43f8-a504-582889bb0748" data-evt-type="18bde62f-bc03-4c32-96fe-bf58df7b81c4"
          attr.data-evt-context-id="{{ customerInfo }}" data-evt-element-id="carta_quitação"
          (click)="openModalDischarge()">
          {{ 'Carta de quitação' | translate }}
        </button>
      </div>
    </div>
  </div>
  <app-loading [show]="isLoading"></app-loading>