<div class="empty mb-5">
  <div class="d-flex justify-content-center align-items-center">
    <img src="./assets/images/check.png" alt="icone de check" rel="preload" />
    <div class="messages">
      <h1 class="ml-4 mt-3">
        {{ 'EMPTY-STATE.TITLE' | translate }}
      </h1>
      <p class="ml-4 mt-2">
        {{ 'EMPTY-STATE.SUBTITLE' | translate }}
      </p>
    </div>
  </div>
</div>