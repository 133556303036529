<app-loading [show]="isLoading"></app-loading>

<div class="modal-wrapper">
  <div class="modal-header">
    <em type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"
      (keypress)="activeModal.dismiss('Cross click')">
      <img src="./assets/images/icon-close.png" alt="icone fechar modal" rel="preload" />
    </em>
  </div>
  <div class="modal-body">
    <h1>{{ 'TERMS-DISCHARGE.TITLE' | translate }}</h1>

    <div class="mt-5">
      <p>
        {{ 'TERMS-DISCHARGE.TEXTS.PARAGRAPH-1' | translate }}
        <strong> {{ dischargeDetail?.ContactName }} </strong>,
        {{ 'TERMS-DISCHARGE.TEXTS.PARAGRAPH-2' | translate }}
        <strong> {{ dischargeDetail?.IdentityNumber }} </strong>,
        {{ 'TERMS-DISCHARGE.TEXTS.PARAGRAPH-3' | translate }}
        <strong> {{ dischargeDetail?.PortfolioName }} </strong>,
        {{ 'TERMS-DISCHARGE.TEXTS.PARAGRAPH-4' | translate }}
        <strong>
          {{ dischargeDetail?.CessionaryName }}
        </strong>
        ( {{ 'TERMS-DISCHARGE.TEXTS.PARAGRAPH-5' | translate }}
        {{ dischargeDetail?.CessionaryIdentityNumber }} ).
      </p>
    </div>
    <div class="d-flex justify-content-between mt-5">
      <p>
        {{ 'TERMS-DISCHARGE.TEXTS.PARAGRAPH-6' | translate }}
      </p>
      <strong class="col-8">
        {{ dischargeDetail?.DebtNumber }}
      </strong>
    </div>
    <div class="d-flex justify-content-between">
      <p>
        {{ 'TERMS-DISCHARGE.TEXTS.PARAGRAPH-7' | translate }}
      </p>
      <strong class="col-8">
        {{ dischargeDetail?.SettlementDate }}
      </strong>
    </div>
    <div class="d-flex justify-content-between">
      <p>
        {{ 'TERMS-DISCHARGE.TEXTS.PARAGRAPH-8' | translate }}
      </p>
      <strong class="col-8">
        {{ dischargeDetail?.DebtTotalBalance | currency: 'BRL':'R$':'1.2-2' }}
      </strong>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="button outline mr-3" data-evt-collect="true"
      data-evt-category="ca7650f8-eef8-43f8-a504-582889bb0748" data-evt-type="18bde62f-bc03-4c32-96fe-bf58df7b81c4"
      attr.data-evt-context-id="{{ customerInfo }}" data-evt-element-id="Enviar por email" (click)="sendToEmail()"
      id="btn_send_letter">
      {{ 'TERMS-DISCHARGE.BUTTONS.SEND-EMAIL' | translate }}
    </button>
    <button class="button primary btn-login" type="button" data-evt-collect="true"
      data-evt-category="ca7650f8-eef8-43f8-a504-582889bb0748" data-evt-type="18bde62f-bc03-4c32-96fe-bf58df7b81c4"
      attr.data-evt-context-id="{{ customerInfo }}" data-evt-element-id="Imprimir" (click)="download()" id="btn_letter">
      {{ 'TERMS-DISCHARGE.BUTTONS.PRINT' | translate }}
    </button>
  </div>
</div>