<ng-container #content>
  <div class="modal-body">
    <div id="container-terms">
      <div class="row">
        <div class="col-md-4 col-lg-4 col-xs-12 col-sm-12">
          <a routerLink="/" (click)="close($event)" tabindex="0">
            <img class="logo-ipanema" src="../assets/images/logo.svg" alt="Ipanema" rel="preload" />
          </a>
        </div>
        <div class="col-md-8 col-lg-8 col-xs-12 col-sm-12">
          <div class="close-button">
            <a class="close-text" (click)="close($event)" routerLink="/"><span class="close-icon">{{
                'TERMS.CLOSE-BUTTON' | translate
                }}</span>
              {{ 'BUTTONS.CLOSE' | translate }}</a>
          </div>
        </div>
      </div>
      <div class="container justify-content-md-center wrap-title">
        <div class="col-sm-10">
          <h1>{{ 'TERMS.TITLE' | translate }}</h1>
        </div>
      </div>
      <div class="container justify-content-md-center terms-body">
        <div class="col-sm-12 text-justify">
          <p>
            Alinhados com as melhores práticas e calçados em nossa filosofia de transparência e respeito, o Return
            Capital S/A, sociedade anônima, com sede na Cidade de São Paulo, Estado de São Paulo, Av. Pres. Juscelino
            Kubitschek, 2041 -26º andar- Vila Olímpia, São Paulo - SP, CEP: 13571-410, inscrita no CNPJ/MF sob o nº
            15.141.003/0001-12, apresenta os TERMOS E CONDIÇÕES DE USO e POLÍTICA DE PRIVACIDADE On-line.

          </p>
          <p>
            Queremos oferecer a melhor experiência durante a sua navegação em nossos sites.
          </p>

          <h6><strong>1. TERMO DE ACEITAÇÃO</strong></h6>
          <p>Ao utilizar os nossos serviços, você aceita e concorda com os Termos e Condições de Uso expostos, que se
            encontram vigentes nesta data.</p>
          <p>Destacamos que estes Termos e Condições de Uso poderão ser modificados a qualquer momento pela Return
            Capital S/A, em virtude de alterações na legislação ou nos Serviços, em decorrência da utilização de novas
            ferramentas tecnológicas ou, ainda, sempre que, a exclusivo critério do FIDC Ipanema, tais alterações se
            façam necessárias. A utilização dos Serviços online disponibilizados (“Serviços”) pelo FIDC Ipanema por
            qualquer usuário implicará em expressa aceitação destes Termos e Condições de Uso.</p>

          <h6>
            <strong>2. ATUALIZAÇÃO DAS INFORMAÇÕES</strong>
          </h6>
          <p>
            O FIDC Ipanema se reserva ao direito de aprimorar as funcionalidades e aplicar novas tecnologias aos
            Serviços, propiciando sempre uma melhor navegação. Assim, os Termos de Uso poderão ser alterados, a qualquer
            tempo, exceto em caso de vedação legal, para incluir as modificações implementadas. Ao continuar a utilizar
            os nossos Serviços após alterados os Termos de Uso, o usuário concorda com as alterações realizadas e
            vigentes à época do acesso.
          </p>

          <h6><strong>3. ACESSO A CONTEÚDO RESTRITO E SUSPENSÃO DE ACESSO</strong> </h6>
          <p>
            Para que o usuário possa ter acesso a todas as nossas ofertas e demais informações, será necessário realizar
            um cadastro em nosso site, com acesso ao ambiente por meio de login e senha, os quais são de uso pessoal e
            intransferível, assim, você responsável pela sua guarda, bom uso e sigilo destes.
          </p>
          <p>
            Considerando que você é responsável pela veracidade das informações cadastradas, informamos que o cadastro
            de informações falsas pode gerar inconsistência na prestação dos Serviços, bem como impactar ou interromper
            o seu acesso.
          </p>
          <p>
            A qualquer tempo, sem aviso prévio, o FIDC Ipanema poderá suspender, cancelar ou interromper o acesso aos
            Serviços, respeitadas as condições da legislação aplicável.
          </p>
          <p>
            O FIDC Ipanema não se responsabiliza por eventuais danos e/ou problemas decorrentes da demora, interrupção
            ou bloqueio nas transmissões de dados decorrentes da conexão de internet do usuário
          </p>

          <h6><strong>4. USO ADEQUADO DOS SERVIÇOS </strong></h6>

          <p>Ao utilizar os Serviços, você se compromete a respeitar a legislação brasileira vigente e o disposto nestes
            Termos e Condições de Uso, não devendo produzir, disponibilizar ou compartilhar qualquer conteúdo que:</p>
          <ul>
            <li>a) Implique a prática de ato ilícito e viole a legislação brasileira vigente;</li>

            <li>b) Viole direitos de terceiro ou direitos do FIDC Ipanema;</li>

            <li>c) Seja falso, incorreto, impreciso, extemporâneo e que possa induzir outrem a erro;</li>

            <li>d) Disponibilize ou permita o acesso a conteúdo ilegal, violento, pornográfico ou qualquer outro ato
              licontrário à lei e à ordem pública;</li>

            <li>e) Induza a discriminação ou incite o ódio contra pessoas e/ou grupos de pessoas em razão de
              nacionalidade,
              liraça, religião, orientação sexual, gênero, condição física, nacionalidade, dentre outros atos que
              contrariem
              lia ordem pública e a legislação brasileira vigente;</li>

            <li>f) Seja resguardado por direito de propriedade intelectual de terceiro e você não tenha autorização
              prévia
              lipara utilizá-lo;</li>

            <li>g) Contenha vírus ou outro elemento que seja capaz, em razão de suas características (como forma,
              extensão
              etc.), de causar danos ou impedir o funcionamento regular da rede do sistema e/ou de equipamentos
              informáticos ("hardware" e "software") do FIDC Ipanema ou de terceiros.</li>
          </ul>



          <h6><strong>5. RESPONSABILIDADES</strong></h6>
          <p>Enquanto você usufruir dos Serviços oferecidos, você é responsável:</p>
          <ul>
            <li>a) Por todo ato, por ação ou omissão, realizado em nossos sites institucionais, responsabilizando-se
              pela
              liprática de todo e qualquer ato ilícito feita por você;</li>

            <li>b) Pelo conteúdo gerado por você;</li>

            <li>c) Pela reparação de danos causados a terceiros, ao FIDC Ipanema, a partir do uso dos Serviços;</li>

            <li>d) Pelo seu próprio acesso à internet e ao pagamento pelos serviços de acesso à rede;</li>

            <li>e) Pelo equipamento necessário para realizar a conexão à internet, como computador e modem, garantindo a
              lisegurança adequada do ambiente, mediante a utilização de ferramentas como antivírus e firewall;</li>

            <li>f) Por não utilizar os Serviços para outras finalidades além daquelas indicadas nos sites institucionais
              e
              liaplicativos mobile.</li>

          </ul>
          <p>Sem prejuízo das demais isenções de responsabilidade indicadas no presente documento, você se declara
            liciente de que o FIDC Ipanema não será responsável:</p>
          <ul>
            <li> a) Por qualquer defraudação de utilidade que você possa atribuir aos Serviços, pela falibilidade
              destes,
              linem por qualquer dificuldade de acesso a estes, decorrentes de condutas atribuíveis a terceiros, como,
              por
              liexemplo, prestadores de serviços de conexão à internet;</li>

            <li>b) Pela presença de vírus ou demais elementos nocivos nos Serviços, capazes de causar alterações em seus
              lisistemas informáticos (software e hardware), documentos eletrônicos, eximindo-se o Santander de qualquer
              liresponsabilidade por eventuais danos e prejuízos decorrentes de quaisquer elementos nocivos inseridos
              por
              literceiros;</li>

            <li>c) Pelos danos e prejuízos de toda natureza decorrentes do conhecimento que terceiros não autorizados
              possam ter de quaisquer dados fornecidos nos Serviços, em decorrência de falha exclusivamente relacionada
              ao
              usuário ou a terceiros que fujam a qualquer controle razoável do FIDC Ipanema.</li>
          </ul>


          <h6><strong>6. PRÁTICAS VEDADAS</strong></h6>
          <p>
            Ao acessar, navegar e/ou se cadastrar em nosso Site, assim como por intermédio de uma de nossas plataformas,
            você declara estar ciente e concorda em não praticar ou promover ações ou omissões que estejam em desacordo
            com nossos Termos e Condições de Uso e Política de Privacidade, tais como, sem limitação:
          </p>
          <ul>
            <li>● Ato ilícito e/ou danoso, independentemente de dolo ou culpa;</li>
            <li>● Fraudes e violações;</li>
            <li>● Violar a legislação ou tratados vigentes;</li>
            <li>● Propagar ou publicar conteúdos sensíveis e/ ou proibidos;</li>
            <li>● Promover ou incitar o ódio, atos discriminatórios, atividades ilegais ou qualquer outra forma de
              preconceito / discriminação;</li>
            <li>● Praticar ilegais / indevidos para acesso a dados contidos em nossos sites e/ou demais plataformas</li>
            <li>● Utilizar de meios para acesso massivo às informações contidas em nossos sites e/ou demais plataformas
              sem prévia e expressa autorização;</li>
            <li>● Violar conteúdo de propriedade intelectual disponível em nossos sites e/ou demais plataformas;</li>
            <li>● Praticar ações que possam impactar direta ou diretamente nossos sites e/ou demais plataformas;</li>

          </ul>

          <div class="container justify-content-md-center wrap-title">
            <div class="col-sm-10">
              <h1>{{ 'TERMS.PRIVACY' | translate }}</h1>
            </div>
          </div>

          <p>Alinhados com as melhores práticas e calcados em sua filosofia de transparência e respeito aos clientes
            FIDC Ipanema, apresentamos os princípios básicos da Política de Privacidade On-Line (chamada “Política de
            Privacidade”).</p>
          <p>Trata-se das diretrizes adotadas em relação à coleta, armazenamento, utilização e todas as demais formas de
            tratamento das informações pessoais dos clientes e visitantes, para acesso e uso dos Serviços pelos sites
            institucionais (denominados "Serviços"). </p>


          <h6><strong>1. DEFINIÇÕES</strong></h6>
          <ul>
            <li>• <strong>Agentes de tratamento:</strong> o Controlador e o Operador. </li>
            <li>• <strong>Controlador:</strong> pessoa natural ou jurídica, de direito público ou privado, a quem
              competem as decisões referentes ao tratamento de dados pessoais. </li>
            <li>• <strong>Dado Pessoal:</strong> toda e qualquer informação relacionada a pessoa natural identificada ou
              identificável. </li>
            <li>• <strong>Dado Pessoal Sensível:</strong> dado pessoal sobre origem racial ou étnica, convicção
              religiosa, opinião política, filiação a sindicato ou a organização de caráter religioso, filosófico ou
              político, dado referente à saúde ou à vida sexual, dado genético ou biométrico, quando vinculado a uma
              pessoa natural. </li>
            <li>• <strong>Operador:</strong> pessoa natural ou jurídica, de direito público ou privado, que realiza o
              tratamento de dados pessoais em nome do controlador; </li>
            <li>• <strong>Titular: </strong> pessoa natural a quem se referem os dados pessoais que são objeto de
              tratamento. </li>
            <li>• <strong>Tratamento:</strong> toda operação realizada com dados pessoais, como as que se referem a
              coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição,
              processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação,
              comunicação, transferência, difusão ou extração. </li>
          </ul>


          <h6><strong>2. QUEM É O RESPONSÁVEL PELO TRATAMENTO DOS DADOS PESSOAIS (CONTROLADOR)?</strong></h6>
          <p>É o FIDC Ipanema (Fundo de Investimento em Direitos Creditórios Multissegmentos NPL Ipanema VI –
            Não Padronizado), inscrito no CNPJ 26.405.883/0001 (“Controlador”), representado por seu Administrador
            BRL TRUST DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A., com sede na cidade
            de São Paulo, Estado de São Paulo, à Rua Alves Guimarães, número 1.212, Pinheiros – CEP: 05410-002,
            inscrita no CNPJ/MF sob o n. 13.486.793/0001-42 </p>

          <h6><strong>3. PARA QUAIS FINALIDADES OS DADOS PESSOAIS SÃO TRATADOS?</strong></h6>
          <p>Nos termos da Lei Geral de Proteção de Dados (Lei nº 13.709/18), o Controlador realiza o tratamento de
            dados pessoais com finalidades específicas e de acordo com as bases legais previstas na respectiva Lei, tais
            como: para o devido cumprimento das obrigações legais e regulatórias, para o exercício regular de direitos e
            para a proteção do crédito, bem como sempre que necessário para a execução dos contratos firmados com seus
            clientes ou para atender aos interesses legítimos do Controlador, de seus clientes ou de terceiros. Para
            qualquer outra finalidade, para a qual o consentimento do titular deve ser coletado, o tratamento estará
            condicionado à manifestação livre, informada e inequívoca do titular. </p>
          <p>O Controlador dos dados nos termos da legislação, poderá tratar, coletar, armazenar e compartilhar com as
            sociedades sob controle direto ou indireto de seu grupo, sempre com a estrita observância à Lei, seus dados
            pessoais e informações cadastrais, financeiras e de operações ativas e passivas e Serviços contratados, bem
            como os registros de dados e de informações com indícios de ocorrências ou tentativas de fraudes, para: </p>

          <ul>
            <li><strong>(i)</strong> garantir maior segurança e prevenir fraudes; </li>
            <li><strong>(ii)</strong> assegurar sua adequada identificação, qualificação e autenticação; </li>
            <li><strong>(iii)</strong> prevenir atos relacionados à lavagem de dinheiro e outros atos ilícitos;</li>
            <li><strong>(iv)</strong> aperfeiçoar e/ou personalizar o atendimento aos Serviços fornecidos; </li>
            <li><strong>(v)</strong> fazer ofertas de Serviços adequados e relevantes aos seus interesses e necessidades
              de acordo com o seu perfil; e </li>
            <li><strong>(vi)</strong> outras hipóteses baseadas em finalidades legítimas. </li>
          </ul>
          <p>O Controlador, para fins de prevenção à fraude, registrará os dados e informações com indícios de
            ocorrências ou tentativas de fraude identificadas nos serviços ou produtos, e poderá realizar o
            compartilhamento desses registros com as Sociedades do Conglomerado ao qual pertence, instituições
            financeiras do Sistema Financeiro Nacional – SFN, e órgãos reguladores, nos termos das normas aplicáveis.
          </p>
          <p>Os novos Serviços on-line, disponibilizados pelo Controlador, estarão automaticamente sujeitos à Política
            de Privacidade vigente à época de sua utilização. </p>


          <h6><strong>4. QUAIS DADOS PESSOAIS SÃO TRATAMOS? 2. QUEM É O RESPONSÁVEL PELO TRATAMENTO DOS DADOS PESSOAIS
              (CONTROLADOR)?</strong></h6>
          <p>Abaixo seguem as situações / dados que estarão automaticamente sujeitos à Política de Privacidade vigente à
            época de sua utilização e poderão ser objeto de tratamento:</p>

          <TABLE WIDTH="100%" CELLPADDING="7" CELLSPACING="0" style="margin-bottom: 40px">
            <COL WIDTH="174" />
            <COL WIDTH="175" />
            <COL WIDTH="174" />
            <TR>
              <th scope="col" WIDTH="174" BGCOLOR="#ffffff" STYLE="border: 1px solid #000001; padding: 0in 0.08in">
                <P CLASS="western" ALIGN="CENTER">Origens de dados pessoais</P>
              </th>
              <th scope="col" WIDTH="175" BGCOLOR="#ffffff" STYLE="border: 1px solid #000001; padding: 0in 0.08in">
                <P CLASS="western" ALIGN="CENTER">
                  Conjunto de dados pessoais
                </P>
              </th>
              <th scope="col" WIDTH="174" BGCOLOR="#ffffff" STYLE="border: 1px solid #000001; padding: 0in 0.08in">
                <P CLASS="western" ALIGN="CENTER"> Famílias e finalidades </P>
              </th>
            </TR>
            <TR>
              <TD WIDTH="174" BGCOLOR="#ffffff" STYLE="border: 1px solid #000001; padding: 0in 0.08in">
                <P CLASS="western" STYLE="margin-bottom: 0in">
                  Os dados foram obtidos:
                </P>
                <P CLASS="western" STYLE="margin-bottom: 0in">
                  • Em razão de cessão de crédito, onde o cedente transfere a titularidade deste crédito e todos os seus
                  direitos e obrigações;
                </P>
                <P CLASS="western" STYLE="margin-bottom: 0in">
                  • Diretamente do titular dos dados através do portal/site, aplicativos ou assistentes virtuais;
                </P>
                <P CLASS="western" STYLE="margin-bottom: 0in">
                  • De fontes externas legítimas, com devido embasamento legal ou contratual; ou
                </P>
                <P CLASS="western">
                  • Em razão de eventual compartilhamento de dados entre empresas do Conglomerado ao qual o Controlador
                  pertence, sem prejuízo do disposto na Lei e das hipóteses em que o consentimento for necessário.
                </P>
              </TD>
              <TD WIDTH="175" BGCOLOR="#ffffff" STYLE="border: 1px solid #000001; padding: 0in 0.08in">
                <P CLASS="western" STYLE="margin-bottom: 0in"><BR /> </P>
                <OL TYPE="a">
                  <LI>
                    <P CLASS="western" STYLE="margin-top: 0.19in; margin-bottom: 0in; border: none; padding: 0in">
                      Nome;
                    </P>
                  </LI>
                  <LI>
                    <P CLASS="western" STYLE="margin-bottom: 0in; border: none; padding: 0in">
                      CPF;
                    </P>
                  </LI>
                  <LI>
                    <P CLASS="western" STYLE="margin-bottom: 0in; border: none; padding: 0in">
                      Endereço;
                    </P>
                  </LI>
                  <LI>
                    <P CLASS="western" STYLE="margin-bottom: 0in; border: none; padding: 0in">
                      E-mail; e
                    </P>
                  </LI>
                  <LI>
                    <P CLASS="western" STYLE="margin-bottom: 0in; border: none; padding: 0in">
                      Telefone.
                    </P>
                  </LI>

                </OL>

                <P CLASS="western" STYLE="margin-left: 0.99in; margin-bottom: 0.19in; border: none; padding: 0in">
                  <BR /><BR />
                </P>
                <P CLASS="western" STYLE="margin-top: 0.19in"><BR /> </P>
              </TD>
              <TD WIDTH="174" BGCOLOR="#ffffff" STYLE="border: 1px solid #000001; padding: 0in 0.08in">
                <P CLASS="western" STYLE="margin-bottom: 0in">• Cumprir obrigações regulatórias ou legais;
                </P>
                <P CLASS="western" STYLE="margin-bottom: 0in">• Exercer direito de defesa em processo judicial,
                  administrativo ou arbitral;
                  administrativo ou arbitral;
                </P>
                <P CLASS="western" STYLE="margin-bottom: 0in">• Cumprir decisões de autoridades, administrativas,
                  regulatórias ou judiciais;
                </P>
                <P CLASS="western" STYLE="margin-bottom: 0in">• Verificar a sua identidade e garantir maior segurança
                  durante a sua navegação em nossos canais, bem como adotar procedimentos de prevenção à fraude, com o
                  intuito de oferecer proteção ao titular ou ao grupo;
                </P>
                <P CLASS="western" STYLE="margin-bottom: 0in">• Executar ações em virtude de aquisição de créditos;
                </P>
                <P CLASS="western" STYLE="margin-bottom: 0in">• Tratar reclamações, dúvidas e solicitações (Atendimento
                  ao Cliente, SAC, Ouvidoria) e prestação de suporte ao titular;
                </P>
                <P CLASS="western" STYLE="margin-bottom: 0in">• Realizar pesquisa de satisfação os Serviços fornecidos;
                </P>
                <P CLASS="western" STYLE="margin-bottom: 0in">• Proceder com auditorias;
                </P>
                <P CLASS="western" STYLE="margin-bottom: 0in">• Analisar dados para aperfeiçoar a usabilidade,
                  experiência
                  e interatividade na utilização dos nossos portais, sites e
                  aplicativos;
                </P>
                <P CLASS="western" STYLE="margin-bottom: 0in">• Fazer ofertas e/ou fornecer recomendações mais
                  assertivas
                  às
                  suas necessidades ou interesses, inclusive mediante campanhas
                  de marketing ou de simulações realizadas;
                </P>
                <P CLASS="western" STYLE="margin-bottom: 0in">• Realizar pesquisas de comunicação e marketing de
                  relacionamento, para melhorar serviços;
                </P>
                <P CLASS="western" STYLE="margin-bottom: 0in">• Promover campanha;
                </P>
                <P CLASS="western" STYLE="margin-bottom: 0in">• Utilizar cookies, conforme Política;
                </P>
                <P CLASS="western">• Realizar manutenção e a atualização cadastral.
                </P>
              </TD>
            </TR>
          </TABLE>
          <h6><strong>5. TRATAMENTO DE INFORMAÇÕES PESSOAIS ?</strong></h6>
          <p>Entende-se por informações pessoais todas aquelas que forem relacionadas a pessoa natural identificada ou
            identificável.</p>
          <p>Para o fornecimento dos Serviços on-line aos seus usuários, o Controlador adota recursos avançados visando
            a proteção das informações pessoais dos usuários e de seus Serviços. </p>
          <p>As informações de caráter pessoal dos usuários da Plataforma, não são divulgadas, exceto nas hipóteses
            expressamente mencionadas neste Termo / Política. </p>
          <p>Tais informações são coletadas e armazenadas utilizando-se rígidos padrões de sigilo e integridade, bem
            como controles de acesso físico e lógico, observando-se sempre os mais elevados princípios éticos e legais.
          </p>
          <p>Caso o usuário decida fornecer suas informações pessoais no portal para usufruir dos Serviços on-line,
            inclusive as informações pessoais sensíveis, tais informações serão tratadas atendendo às finalidades
            definidas em contrato de prestação de Serviços e nos Termos e Condições de Uso e Política de Privacidade.
          </p>
          <p>Uma vez provido das informações pessoais a respeito do usuário, o Controlador poderá utilizar, de acordo
            com o seu legítimo interesse, os dados do usuário para o fim de enviar informativos de cobrança, direcionado
            por e-mail ou por quaisquer outros meios de comunicação, contendo informações sobre o grupo e
            compartilhá-las com seus parceiros. Entretanto, fica reservado ao usuário o direito de, a qualquer momento,
            inclusive no ato da disponibilização das informações pessoais, informar por meio dos canais de comunicação
            disponíveis para o cadastramento de tais informações, do não interesse em receber tais anúncios /
            comunicados. </p>
          <h6><strong>6. TRANSFERÊNCIA INTERNACIONAL</strong></h6>
          <p>
            Os dados pessoais tratados em decorrência do uso dos Serviços serão processados em conformidade com
            legislação vigente aplicável.
          </p>
          <p>
            O Controlador está sediada no Brasil, mas pode realizar o tratamento de dados pessoais em outros países
            através da contratação de empresas parceiras, estas que, por sua vez, estarão submetidas às obrigações desta
            Política de Privacidade.
          </p>
          <h6><strong>7. COMO RESPONDEMOS OS REQUISITOS LEGAIS? </strong></h6>
          <p>
            Quando alguma autoridade requerer o acesso a seus dados pessoais e este pedido estiver respaldado por lei
            vigente, o Controlador compartilhará as suas informações. Nestes casos, o Controlador irá fornecer as
            informações estritamente necessárias para o cumprimento do pedido ou de nosso programa de integridade.
          </p>
          <h6><strong>8. EXERCÍCIO DE DIREITOS </strong></h6>
          <p>
            Em cumprimento à regulamentação aplicável, quando de sua vigência,
            no que diz respeito ao tratamento de dados pessoais, em especial a
            Lei Geral de Proteção de Dados (Lei nº 13.709/2018), o Controlador respeita e garante a você, a
            possibilidade de apresentação de
            solicitações baseadas nos seguintes direitos:
          </p>
          <ul>
            <li>• Confirmação da existência de tratamento; </li>
            <li>• Acesso aos dados; </li>
            <li>
              • Correção de dados incompletos, inexatos ou desatualizados;
            </li>
            <li>
              • A anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em
              desconformidade com a Lei;
            </li>
            <li>• Portabilidade dos dados a outro fornecedor; </li>
            <li>
              • Eliminação dos dados tratados com consentimento do Usuário, exceto nas hipóteses de guarda legal e
              outras dispostas em Lei;
            </li>
            <li>
              • Obtenção de informações sobre as entidades públicas ou privadas com as quais o FIDC / Controlador
              compartilharam seus dados;
            </li>
            <li>
              • Informação sobre a possibilidade de não fornecer o seu consentimento, bem como de ser informado sobre as
              consequências, em caso de negativa;
            </li>
            <li>• Revogação do consentimento.

            </li>
          </ul>
          <p>
            Em desejando exercer um ou mais direitos acima elencados, bem como em casos de dúvidas, você poderá entrar
            em contato conosco através de nossos canais de atendimento.
          </p>
          <p>Para cumprimento da legislação de Dados vigente, em casos de dúvidas relacionadas a Lei Geral de Proteção
            de Dados (LGPD) informamos o nome e endereço de e-mail do Encarregado de Proteção de Dados (DPO - Data
            Protection Officer): Gustavo Cesar Goncalves Soares - dpo@negocieipanema.com.br.</p>
          <p>O Controlador empreenderá todos os esforços para atender tais pedidos no menor espaço de tempo possível. No
            entanto, em caso de requisição de exclusão, será respeitado o prazo de armazenamento mínimo de informações
            de usuários de aplicações de internet, determinado </p>

          <h6><strong>9. COMO TRATAMOS AS SUAS INFORMAÇOES DE FORMA SEGURA? </strong></h6>
          <p>
            O acesso às informações pessoais coletadas, armazenadas ou de outra forma tratadas pelo Controlador é
            restrito aos profissionais autorizados ao uso direto dessas informações, e necessário à prestação de seus
            Serviços, sendo limitado o uso para outras tarefas.
          </p>
          <p>
            É exigido, também, de toda organização ou do indivíduo contratado para a prestação de Serviços de apoio, que
            sejam cumpridas as Políticas de Segurança da Informação adotado pelo Controlador.
          </p>
          <p>
            O Controlador poderá revelar as informações pessoais que tenha recebido nas seguintes hipóteses:
          </p>
          <ul>
            <li>
              • sempre que estiver obrigado a revelá-las, seja em virtude de dispositivo legal, ato de autoridade
              competente, ordem ou mandado judicial;
            </li>
            <li>
              • aos seus parceiros comerciais e/ou prestadores de serviço, a fim de atender à solicitação de Serviços
              efetuada pelos usuários;
            </li>
            <li>
              • aos órgãos de proteção e defesa de crédito e prestadores de Serviços autorizados pelo FIDC Ipanema a
              defender seus direitos e créditos;
            </li>
            <li>• aos órgãos que administrem cadastros de consumidores; </li>
            <li>
              • aos seus controladores, às empresas por ele controladas, ou por qualquer forma associadas, no Brasil ou
              no exterior;
            </li>
          </ul>
          <p>
            O Controlador trabalha exaustivamente para assegurar que as informações divulgadas para os usuários sejam
            verdadeiras e íntegras, contando com controles apurados de monitoramento das informações fornecidas. Sua
            participação no processo é revisar as informações, valores e informativos e enviar para o usuário, um
            comunicado de qualquer discrepância nas informações fornecidas.
          </p>

          <h6><strong>10. EXTENSÃO DOS EFEITOS </strong></h6>
          <p>
            Os termos da Política de Privacidade aqui expostos serão aplicados exclusivamente às informações pessoais
            conforme acima definido, que venham a ser disponibilizadas ao Controlador, pelo usuário, ou outro meio para
            a utilização de seus Serviços.
          </p>
          <p>
            Por consequência, a Política de Privacidade aqui exposta não será aplicável a qualquer outro serviço on-line
            que não os disponibilizados pelo Controlador, incluídos aqueles sites que estejam de alguma forma vinculados
            e estes, por meio de links ou quaisquer outros recursos tecnológicos, e, ainda, a quaisquer outros sites
            que, de qualquer forma, venham a ser conhecidos ou utilizados.
          </p>
          <p>
            Nesse sentido, alertarmos aos usuários que os referidos sites podem conter política de privacidade diversa
            da adotada pelo Controlador ou podem até mesmo não adotar qualquer política nesse sentido, não se
            responsabilizando, o Controlador, por qualquer violação aos direitos de privacidade dos usuários que venha a
            ser praticada pelos referidos sites.
          </p>

          <h6><strong>11. INTERPRETAÇÃO DOS TERMOS </strong></h6>
          <p>
            As palavras e os termos constantes desta Política de Privacidade aqui não expressamente definidos, grafados
            em português ou em qualquer outro idioma, bem como, quaisquer outros de linguagem técnica e/ou financeira ou
            não, que, eventualmente, durante a vigência do presente instrumento, no cumprimento de direitos e obrigações
            assumidos por ambas as partes, sejam utilizados para identificar a prática de quaisquer atos, deverão ser
            compreendidos e interpretados em consonância com o conceito internacionalmente consagrado.
          </p>

          <h6><strong>12. SOLICITAÇÕES E/OU RECLAMAÇÕES </strong></h6>
          <p>
            Caso você tenha alguma solicitação ou reclamação, pedimos que entre em contato pelo nosso “Fale Conosco” ou
            atendimento. Ao entrar em contato, pediremos alguns dados pessoais para confirmarmos a sua identidade.
          </p>
          <p>
            Eventualmente, outras informações podem ser solicitadas para confirmar a sua identidade com o objetivo de
            melhor atender à sua solicitação ou reclamação, havendo dúvida sobre a veracidade ou legitimidade das
            informações informadas.
          </p>

          <h6><strong>13. DISPOSIÇÕES GERAIS </strong></h6>
          <p>
            Eventuais omissões ou meras tolerâncias das partes no exigir o estrito e pleno cumprimento desta Política de
            Privacidade e/ou de prerrogativas decorrentes dele ou da lei, não constituirão novação ou renúncia, nem
            afetarão o exercício de quaisquer direitos aqui previstos, que poderão ser plena e integralmente exercidos,
            a qualquer tempo.
          </p>
          <p>
            Caso se perceba que uma disposição é nula, as disposições restantes desta Política de Privacidade
            permanecerão em pleno vigor e um termo válido substituirá o termo nulo, refletindo nossa intenção, tanto
            quanto possível.
          </p>

          <h6><strong>14. LEI(S) APLICÁVEL(IS) E RESOLUÇÃO DE CONFLITOS </strong></h6>

          <p>
            Toda e qualquer controvérsia oriunda dos termos expostos na presente Política de Privacidade serão
            solucionados de acordo com a legislação brasileira, sendo competente o foro da cidade de São Paulo, Estado
            de São Paulo, com exclusão de qualquer outro por mais privilegiado que seja.
          </p>
          <p>
            Fica claro, ainda, que a utilização de Serviços e as ordens comandadas fora do território brasileiro, ou
            ainda as decorrentes de operações iniciadas no exterior podem estar sujeitas também à legislação e
            jurisdição das autoridades dos países onde forem comandadas ou iniciadas.
          </p>
        </div>
      </div>
    </div>
  </div>
</ng-container>